// @flow

import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryLoader } from 'react-relay'
import { type PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { get } from 'lodash'

import { baselineScoreQuery } from 'platform_web/pages/Insights/tabs/Outcomes/queries/BaselineScoresQuery'
import {
  Box,
  FlexContainer,
  HorizontalStackBar,
  IconNew,
  ScrollableBox,
  Tooltip,
} from 'care-ui'
import { InfoIcon } from 'care-ui/atoms/icons-new'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import type { OutcomeReportingQueryVariableType } from '../../hooks/useOutcomeReporting'
import { BASELINE_SCORES_LEGENDS } from '../../hooks/useOutcomeReporting'
import BaselineScoresEmptyState from '../BaselineScoresEmptyState'

import { type BaselineScoresQuery } from '../../queries/__generated__/BaselineScoresQuery.graphql'

type ComponentProps = {
  queryReference: PreloadedQuery<BaselineScoresQuery>,
}

const Component = (props: ComponentProps) => {
  const { queryReference } = props

  const queryResult = usePreloadedQuery(baselineScoreQuery, queryReference)
  const baselineScores = get(
    queryResult,
    'viewer.outcome_report.baseline_scores',
  )

  const hasBaselineScores = !!baselineScores.length

  // Translations
  const { t: translation } = useTranslation('insights')

  return (
    <Box marginY="xl">
      <TextNew as="div" typography="h2">
        {translation('baseline_scores')}
      </TextNew>

      <FlexContainer alignItems="center" justifyContent="space-between">
        <FlexContainer alignItems="center">
          <TextNew as="span" typography="bodyMd">
            {translation(
              'initial_domain_ratings_recorded_during_the_selected_time_period',
            )}
          </TextNew>

          <Tooltip
            text={translation(
              'domain_ratings_have_been_normalised_into_a_standard_developed_by_innowell',
            )}
            note={translation(
              'note_percentages_have_been_rounded_to_whole_numbers',
            )}
            variant="info"
            width="480px"
          >
            <IconNew as={InfoIcon} />
          </Tooltip>
        </FlexContainer>

        <FlexContainer gap="xxs" alignItems="center">
          {hasBaselineScores &&
            BASELINE_SCORES_LEGENDS.map(legend => (
              <FlexContainer
                alignItems="center"
                justifyContent="center"
                key={legend.name}
              >
                <Box
                  width="16px"
                  height="16px"
                  marginX="4px"
                  backgroundColor={legend.color}
                />
                <TextNew as="span" typography="bodyMd">
                  {legend.name}
                </TextNew>
              </FlexContainer>
            ))}
        </FlexContainer>
      </FlexContainer>

      {hasBaselineScores && (
        <ScrollableBox width="1156px" direction="horizontal">
          <FlexContainer direction="column" gap="sm" marginTop="sm">
            {baselineScores.map(baselineScore => (
              <HorizontalStackBar
                key={baselineScore.health_domain_label}
                title={baselineScore.health_domain_label}
                total={baselineScore.total}
                series={baselineScore.series}
              />
            ))}
          </FlexContainer>
        </ScrollableBox>
      )}

      {!hasBaselineScores && <BaselineScoresEmptyState />}
    </Box>
  )
}

type BaselineScoresProps = {
  outcomeReportQueryVariables: OutcomeReportingQueryVariableType,
}

// Query Loader
const BaselineScores = (props: BaselineScoresProps) => {
  const { outcomeReportQueryVariables } = props
  const { quarter_start_date } = outcomeReportQueryVariables

  const [baselineScoreQueryReference, loadBaselineScoreQuery] = useQueryLoader(
    baselineScoreQuery,
  )

  // Load query when query variables change
  useEffect(
    () => {
      if (!quarter_start_date) return
      loadBaselineScoreQuery(outcomeReportQueryVariables)
    },
    [outcomeReportQueryVariables],
  )

  return (
    <Box marginY="xl">
      <Suspense>
        {baselineScoreQueryReference != null && (
          <Component queryReference={baselineScoreQueryReference} />
        )}
      </Suspense>
    </Box>
  )
}

export default BaselineScores
