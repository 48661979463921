// @flow

import React, { type ComponentType, type Node, useEffect } from 'react'
import { ThemeProvider } from 'react-fela'
import { useDispatch, useSelector } from 'react-redux'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'
import { useRouter } from 'found'
import { compact, size } from 'lodash/fp'

import { NotificationDisplayLoader } from 'components/NotificationDisplay'
import ReadOnlyBanner from 'components/ReadOnlyBanner'
import { ReleaseWarning } from 'components/ReleaseWarning'
import PageActions from 'components/RootPlatformPageAction'
import SessionTimeoutWarning from 'components/SessionTimeoutWarning'
import SessionTimeoutWarningBanner from 'components/SessionTimeoutWarningBanner'
import Banner from 'react-ui/components/Banner'
import Page from 'react-ui/components/Page'
import { OnboardingStepContextProvider } from 'react-ui/contexts/OnboardingStepContext'
import { TenantContextProvider } from 'react-ui/contexts/TenantContext'
import { UserContextProvider } from 'react-ui/contexts/UserContext'
import clinicianTheme from 'react-ui/themes/synergy-clinician-theme'
import individualTheme from 'react-ui/themes/synergy-individual-theme'
import ClinicianOnboarding from 'pages/Onboarding/Clinician/components/ClinicianOnboarding'
import ProviderWrapper from 'containers/ProviderWrapper'
import { ViewerEnumProvider } from 'containers/ViewerEnums'
import { changeCurrentUserRole } from 'redux/currentuser/actions'
import { clearFlash } from 'redux/generic_ui/actions'
import { addPolicies } from 'redux/policies/actions'
import UserNotifications from 'platform_web/components/UserNotifications'
import NavigationProvider from 'platform_web/containers/NavigationProvider'
import useOnboardingSteps from 'platform_web/hooks/useOnboardingSteps'
import { MultiFactorAuthenticationLoader } from 'platform_web/pages/authentication/MultiFactorAuthentication'
import {
  ConnectedOnboardingIndividualPage,
  ConnectedQuestionnaireIndividualPage,
} from 'platform_web/pages/Onboarding'
import newTheme from 'care-ui/atoms'

import {
  startHeartbeat,
  stopHeartbeat,
} from '../../shared/services/api/heartbeat'
import { SystemNotificationPopUp } from '../components/SystemNotificationPopUp'

import { type RootPlatformPage_viewer } from './__generated__/RootPlatformPage_viewer.graphql'

type PropsType = {
  children: ComponentType<Node>,
  viewer: RootPlatformPage_viewer,
}

const chooseThemeForRole = (role: ?string) => {
  const newThemeObject = {
    care: newTheme,
  }
  const clinicianCombined = { ...clinicianTheme, ...newThemeObject }
  const individualCombined = { ...individualTheme, ...newThemeObject }

  switch (role) {
    case 'ADMIN':
    case 'CLINICIAN':
    case 'LEAD_CLINICIAN':
    case 'MANAGER':
    case 'OWNER':
    case 'RESEARCHER':
    case 'SUPER': {
      return clinicianCombined
    }
    default:
      return individualCombined
  }
}

const RootPlatformPageBase = ({ children, viewer }: PropsType) => {
  const { onboardingSteps, setCurrentStep } = useOnboardingSteps()
  const environment = useRelayEnvironment()
  const { router } = useRouter()

  const dispatch = useDispatch()
  const showOnboardingQuestionnaireSuccess = useSelector(
    ({ genericUi }) => genericUi.showOnboardingQuestionnaireSuccess,
  )

  const { currentUser, remember_me: rememberMe } = viewer

  const {
    mfa_code_required,
    completed_onboarding = false,
    policies,
    current_role: currentRole,
    show_individual_onboarding: showIndividualOnboarding,
    show_clinician_onboarding: showClinicianOnboarding,
    unacknowledged_pop_ups: unacknowledgedPopUps,
    user_research_programs_responses: researchResponses,
    unanswered_tenant_research_programs: unansweredResearch,
  } =
    currentUser || {}

  const updateCurrentUser = () => {
    const { id, current_role, roles, owner_tenants } = currentUser || {}
    const { role_type, tenant } = current_role || {}
    const mappedRoles = roles?.nodes?.map(role => role?.role_type)
    const filteredRoles = compact(mappedRoles)

    if (role_type && (tenant || owner_tenants) && filteredRoles?.length) {
      const currentUserRole = changeCurrentUserRole(
        id,
        tenant || {},
        owner_tenants,
        role_type,
        filteredRoles,
      )
      dispatch(currentUserRole)
    }
  }

  useEffect(
    () => {
      if (currentUser) {
        dispatch(clearFlash())
        if (!window.GLOBALS.features?.DISABLE_API_HEARTBEAT) startHeartbeat()
        return () => {
          stopHeartbeat()
        }
      }
      return () => {}
    },
    [currentUser],
  )

  useEffect(
    () => {
      if (currentUser) {
        updateCurrentUser()
        dispatch(addPolicies(policies))
      }
    },
    [currentUser],
  )

  if (!currentUser || !policies) {
    router.replace('signingOut')
    return null
  }

  const {
    parent,
    tenant,
    role_type: roleType,
    individual_detail: individualDetail,
    see_help_now_button: showHelpNowButton,
  } =
    currentRole || {}

  const theme = chooseThemeForRole(roleType)
  const showOnboarding = !completed_onboarding
  const isIndividual = roleType === 'INDIVIDUAL'

  const tenantResearchExist = size(unansweredResearch) + size(researchResponses)

  const {
    RESEARCH_AND_DATA_SHARING,
    ...restOfOnboardingSteps
  } = onboardingSteps

  const currentOnboardingSteps = tenantResearchExist
    ? { RESEARCH_AND_DATA_SHARING, ...(restOfOnboardingSteps: any) }
    : restOfOnboardingSteps

  const showReadOnlyBanner = individualDetail && individualDetail.read_only
  const actionsProps = { tenant, showHelpNowButton }

  const ThemeProviderObject = { provider: ThemeProvider, props: { theme } }
  const TenantContextProviderObject = {
    provider: TenantContextProvider,
    props: { parent },
  }
  const UserContextProviderObject = {
    provider: UserContextProvider,
    props: { user: currentUser },
  }
  const ViewerEnumProviderObject = {
    provider: ViewerEnumProvider,
    props: { viewer },
  }
  const NavigationProviderObject = {
    provider: NavigationProvider,
    props: { viewer, user: currentUser },
  }
  const OnboardingStepContextProviderObject = {
    provider: OnboardingStepContextProvider,
    props: { currentOnboardingSteps, setCurrentStep },
  }

  if (mfa_code_required) {
    return (
      <ThemeProvider theme={individualTheme}>
        <Page>
          <MultiFactorAuthenticationLoader user={currentUser} />
        </Page>
      </ThemeProvider>
    )
  }

  if (showOnboarding && !isIndividual) {
    const { id } = individualDetail || {}

    return (
      <ProviderWrapper
        providers={[
          ThemeProviderObject,
          TenantContextProviderObject,
          UserContextProviderObject,
          OnboardingStepContextProviderObject,
        ]}
      >
        <Page>
          <ConnectedOnboardingIndividualPage
            id={id}
            tenantResearchPrograms={unansweredResearch}
            isOnboardUserIndividual={isIndividual}
            user={currentUser}
          />
        </Page>
      </ProviderWrapper>
    )
  }

  if (showIndividualOnboarding === true || showOnboardingQuestionnaireSuccess) {
    return (
      <ProviderWrapper
        providers={[
          ThemeProviderObject,
          TenantContextProviderObject,
          UserContextProviderObject,
          ViewerEnumProviderObject,
          OnboardingStepContextProviderObject,
        ]}
      >
        <ConnectedQuestionnaireIndividualPage
          viewer={viewer}
          user={currentUser}
        />
      </ProviderWrapper>
    )
  }

  if (showClinicianOnboarding === true) {
    return (
      <ProviderWrapper
        providers={[
          ThemeProviderObject,
          TenantContextProviderObject,
          UserContextProviderObject,
        ]}
      >
        <ClinicianOnboarding user={currentUser} environment={environment} />
      </ProviderWrapper>
    )
  }

  return (
    <ProviderWrapper
      providers={[
        ThemeProviderObject,
        NavigationProviderObject,
        ViewerEnumProviderObject,
        TenantContextProviderObject,
        UserContextProviderObject,
      ]}
    >
      <Page actions={<PageActions {...actionsProps} />}>
        {showReadOnlyBanner && <ReadOnlyBanner />}
        {isIndividual &&
          tenant &&
          tenant.bannerText && (
            <Banner
              componentId="tenantBanner"
              extend={
                {
                  container: { padding: theme.Grid.gutter },
                } /* TODO: the Banner component needs to be refactored to handle styles like this */
              }
            >
              {tenant.bannerText}
            </Banner>
          )}
        <UserNotifications user={currentUser} />
        <ReleaseWarning localVersion={process.env.RELEASE} />
        {children}
        <NotificationDisplayLoader user={currentUser} tenant={tenant} />
        <SystemNotificationPopUp notifications={unacknowledgedPopUps} />
        {!rememberMe && (
          <>
            <SessionTimeoutWarning warningAt={240} warningHideAt={0}>
              {() => (
                <SessionTimeoutWarningBanner>
                  Your session will timeout soon. Click here to re-load and
                  avoid having to sign in again.
                </SessionTimeoutWarningBanner>
              )}
            </SessionTimeoutWarning>
            <SessionTimeoutWarning warningAt={0}>
              {() => (
                <SessionTimeoutWarningBanner>
                  Your session has now timed out. Click here to sign in again.
                </SessionTimeoutWarningBanner>
              )}
            </SessionTimeoutWarning>
          </>
        )}
      </Page>
    </ProviderWrapper>
  )
}

export const RootPlatformPageQuery = graphql`
  query RootPlatformPage_Query {
    viewer {
      ...RootPlatformPage_viewer
    }
  }
`

graphql`
  fragment RootPlatformPage_user on User {
    id
    show_clinician_onboarding
    show_individual_onboarding
    mfa_code_required
    user_research_programs_responses {
      id
    }
    unanswered_tenant_research_programs {
      id
      research_program {
        id
        title
        description
      }
    }
    current_role {
      ... on IndividualRole {
        individual_detail {
          id
          read_only
        }
      }
      ... on RoleInterface {
        id
        see_help_now_button
        ...IndividualsAdminInvite_current_role
        tenant {
          id
          label
          created_at
          bannerText
          multi_roles_enabled
          hide_all_unassigned_individuals_for_clinician
        }
        role_type
      }
    }
    completed_onboarding
  }
`

export const RootPlatformPageLoader = createFragmentContainer(
  RootPlatformPageBase,
  {
    viewer: graphql`
      fragment RootPlatformPage_viewer on Viewer {
        remember_me
        ...ViewerEnumProvider_viewer
        currentUser {
          ...ClinicianOnboarding_user
          ...NotificationDisplay_user
          ...ConnectedQuestionnaireIndividualPage_user
          ...NavigationProvider_user
          ...UserCardLoader_user
          ...UserNotifications_user
          ...MultiFactorAuthentication_user
          ...RootPlatformPage_user @relay(mask: false)
          ...UserContext_user
          roles {
            nodes {
              ... on RoleInterface {
                id
                role_type
              }
            }
          }
          policies {
            name
            enabled
          }
          current_role {
            ... on RoleInterface {
              parent {
                ...TenantContext_parent
              }
              tenant {
                ...NotificationDisplay_tenant
              }
            }
          }
          owner_tenants {
            id
            name
            created_at
          }
          unacknowledged_pop_ups {
            ...SystemNotificationPopUp_notifications
          }
        }
      }
    `,
  },
)
