// @flow

/* @file
 * Used to display a loading / error message when <QueryRenderer /> components are loading data.
 */

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { TinyLoader } from 'react-ui/components/LoadingSpinner'

type PropsType = {
  +children: (props: Object) => React.Node,
  +response: {
    +error: Object,
    +props: Object,
  },
}

export const QueryRendererLoadingIndicator = (props: PropsType) => {
  const { children, response: { error, props: data } } = props
  const { t: translation } = useTranslation('dashboard')

  if (error) {
    return <p>{error.message}</p>
  } else if (data) {
    return children(data)
  }

  return <TinyLoader message={translation('Loading')} />
}
