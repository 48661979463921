// @flow

// Localization Plans
// 1. Have a single trnanslation resource JSON file. - Done
// this will help to automate the process of updating any locale to our platform
// We can get rid of script to generate JSON and can only generate CSV
// 2. For optimization purpose, translations will be divided based on project folder structure -Done [In progress]
// 3. There will be no duplicate text, for this all text used in multiple places will be categorasied under app
// 4. Key name should be downcase and underscored whole text

const resources = {
  en: {
    assessment: {
      a_quick_check_in_on_mental_health_incorporating_multiple_instruments_and_evaluating_general_health_history_and_wellbeing:
        'A quick check-in on mental health, incorporating multiple instruments, and evaluating general health history and wellbeing.',
      answer_one: 'Answer',
      answer_other: 'Answers',
      answered_by: 'Answered by',
      answered_on: 'Answered on',
      answered: 'Answered',
      assessments_that_dont_apply_to_the_patient_have_been_removed_from_the_list:
        "Assessments that don't apply to the patient have been removed from the list.",
      assessments: 'Assessments',
      back_to_details: 'Back to details',
      complete_all: '(Complete all)',
      completed: 'Completed',
      full_questionnaire: 'Full Questionnaire',
      incomplete: 'Incomplete',
      individual: 'Individual',
      last_answered: 'Last Answered',
      library: 'Library',
      no_assessment_request: 'No assessment requests found',
      no_results_found: 'No results found',
      provide_a_comprehensive_overview_of_mental_health_across_multiple_domains_evaluating_symptoms_functioning_risk_indicators_and_support_systems:
        'Provide a comprehensive overview of mental health across multiple domains, evaluating symptoms, functioning, risk indicators, and support systems.',
      question_set: 'Question Set',
      question: 'Question',
      questionnaire: 'Questionnaire',
      rating: 'Rating',
      request_assessments: 'Request Assessments',
      requested_assessments: 'Requested Assessments',
      requested_on: 'Requested on',
      requested: 'Requested',
      search_bar: 'Search Bar',
      search_for_questionnaire: 'Search for questionnaire...',
      select_a_person: 'Select a person',
      select_person_to_send_a_request_to: 'Select person to send a request to',
      select_questionnaire_to_request: 'Select questionnaire(s) to request',
      send_requests: 'Send request(s)',
      skipped: 'Skipped',
      status: 'Status',
      summary_questionnaire: 'Summary Questionnaire',
      take_assessment: 'Take assessment',
      unanswered: 'Unanswered',
      updating_list: 'Updating List',
      view_answers_from: 'View answers from',
      view_answers: 'View Answers',
      out_of: 'out of',
      approx: 'approx',
      to_complete: 'to complete',
      secure_and_confidential: 'secure and confidential',
      saved_automatically: 'saved automatically',
      skip: 'Skip',
      next: 'Next',
      submit: 'Submit',
      submitting: 'Submitting',
      need_one: 'need',
      need_other: 'needs',
      completing: 'completing',
      sorry_your_role_is_not_supported_for_questionnaires:
        'Sorry, your role is not supported for Questionnaires.',
      well_done: 'Well done',
      you_are_almost_done: "You're almost done",
      of_the_initial_questionnaire_completed:
        'of the initial questionnaire completed',
      you_can_still_submit_your_answers_and_return_to_the_skipped_incomplete_questions_anytime_or_if_you_prefer_you_can_choose_to_do_them_with_a_support_person_or_your_clinician:
        'You can still submit your answers and return to the skipped/incomplete questions anytime or if you prefer you can choose to do them with a support person or your clinician',
      your_dashboard_has_sorted_your_results_into_health_cards_so_you_can_focus_on_whats_important_and_explore_a_range_of_care_options_that_help_you_meet_your_health_goals:
        'Your dashboard has sorted your results into health cards so you can focus on what’s important and explore a range of care options that help you meet your health goals.',
      you_can_discuss_your_results_with_your_clinician_your_clinician_will_work_with_you_through_the_dashboard_to_tailor_treatment_to_your_needs:
        'You can discuss your results with your clinician. Your clinician will work with you through the dashboard to tailor treatment to your needs.',
      skipped_and_incomplete_sections: 'Skipped and Incomplete Sections',
      completed_sections: 'Completed Sections',
    },
    auth: {
      already_have_an_account: 'Already have an account?',
      back_to_login: 'Back to login',
      current_password: 'Current Password',
      current_password_is_required: 'Current password is required',
      email: 'Email',
      email_is_a_required_field: 'Email is a required field',
      email_is_required: 'Email is required',
      email_must_be_a_valid_email: 'Email must be a valid email',
      enter_old_password: 'Enter old password',
      enter_verification_code: 'Enter verification code',
      forgot_your_password: 'Forgot your password?',
      get_help_signing_in: 'Get help signing in.',
      i_cant_set_it_up_now_log_me_out: "I can't set it up now, log me out",
      i_dont_have_the_code_now_log_me_out:
        "I don't have the code now, log me out",
      if_the_email_address_or_region_provided_is_registered_on_our_system_an_email_with_the_instructions_to_reset_the_password_will_be_sent_shortly_this_password_reset_is_only_valid_for_the_next_6_hours:
        'If the email address or region provided is registered on our system, an email with the instructions to reset the password will be sent shortly. This password reset is only valid for the next 6 hours.',
      if_you_do_not_receive_an_email_in_5_minutes_please_check_your_region_setting_and_email_address:
        'If you do not receive an email in 5 minutes, please check your region setting and email address.',
      invalid_verification_code: 'Invalid verification code',
      keep_me_signed_in: 'Keep me signed in',
      must_match_password: 'Must match password',
      new_passwords_must_match: 'New passwords must match',
      next: 'Next',
      password: 'Password',
      password_confirmation: 'Password confirmation',
      password_confirmation_is_required: 'Password confirmation is required',
      password_is_a_required_field: 'Password is a required field',
      password_is_required: 'Password is required',
      password_must_be_at_least_6_characters:
        'Password must be at least 6 characters',
      password_must_be_at_least_8_characters:
        'Password must be at least 8 characters',
      please_check_your_email: 'Please check your email',
      please_create_a_new_password: 'Please create a new password',
      please_enter_verification_code: 'Please enter verification code',
      please_enter_your_authenticator_app_verification_code_below:
        'Please enter your authenticator app verification code below.',
      please_enter_your_email_below_to_receive_password_reset_instructions:
        'Please enter your email below to receive password reset instructions',
      qr_code: 'QR Code',
      request_new_invite: 'Request new invite',
      request_the_link_again: 'Request the link again',
      save: 'Save',
      scan_the_qr_code_with_your_authentication_app_and_enter_the_verification_code_below:
        'Scan the QR code with your authentication app and enter the verification code below.',
      secure_your_account: 'Secure your account',
      select_your_region: 'Select your region',
      send_instructions: 'Send instructions',
      sign_in: 'Sign In',
      sorry_an_error_occured_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again:
        "Sorry, an error occured with our server. We've notified our team so that they can address it. Please try again.",
      sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_refresh_the_page_before_trying_to_login_again:
        "Sorry, an error occurred with our server. We've notified our team so that they can address it. Please refresh the page before trying to login again.",
      sorry_we_couldnt_change_your_passowrd:
        "Sorry, we couldn't change your passowrd",
      sorry_your_new_password: 'Sorry, your new password',
      sorry_your_new_password_is: 'Sorry, your new password is',
      sorry_your_token: 'Sorry, your token',
      success: 'Success',
      this_invite_is_invalid: 'This invite is invalid.',
      update_your_password: 'Update your password',
      use_the_button_below_to_request_a_new_invite_from_your_service:
        'Use the button below to request a new invite from your service.',
      username: 'Username',
      username_is_a_required_field: 'Username is a required field',
      welcome_to_the_innowell_platform: 'Welcome to the Innowell Platform',
      your_password_has_been_updated: 'Your password has been updated.',
    },
    careUI: {
      graphsComponent: {
        apply: 'Apply',
        cancel: 'Cancel',
        custom: 'Custom',
        monthly: 'Monthly',
        signIn: 'sign in',
        signIns: 'sign ins',
        weekly: 'Weekly',
        yearly: 'Yearly',
      },
      pageHeaderComponent: {
        backToDashboardButton: 'Back to dashboard',
      },
    },
    dashboard: {
      by_keeping_up_to_date_you_can_track_your_health_and_well_being_over_time:
        'By keeping up-to-date you can track your health and well-being over time.',
      click_here: 'click here',
      click_here_to_start: 'Click here to start',
      click_on_a_health_card_to_find_fact_sheets_browse_a_number_of_care_options_and_review_your_results:
        'Click on a health card to find fact sheets, browse a number of care options and review your results.',
      common_tasks: 'Common Tasks',
      continue: 'Continue',
      download_a_pdf_of_their_health_history:
        'Download a PDF of their health history.',
      download_health_history_pdf: 'Download health history PDF',
      finish_questionnaire: 'Finish questionnaire',
      finish_the_questionnaire_to_create_a_baseline_of_your_health_and_well_being:
        'Finish the questionnaire to create a baseline of your health and well-being.',
      for_more_information_on_the_use_of_your_data:
        'For more information on the use of your data,',
      full_questionnaire: 'Full questionnaire',
      give_input: 'Give input',
      health_history_pdf: 'Health history PDF',
      i_accept: 'I accept',
      i_decline: 'I decline',
      invite_edit_support_person: 'Invite/Edit support person',
      it_appears_we_dont_have_all_your_answers:
        'It appears we don’t have all your answers.',
      its_your_history_and_you_can_take_it_with_you:
        "It's your history and you can take it with you.",
      loading: 'Loading',
      no_health_cards_found: 'No health cards found.',
      out_of: 'out of',
      please_ensure_you_take_appropriate_steps_to_protect_the_privacy_of_this_document_in_accordance_with_the_clinical_governance_requirements:
        'Please ensure you take appropriate steps to protect the privacy of this document in accordance with the clinical governance requirements',
      provide_feedback_to_mark_progress_of_your_service:
        'Provide feedback to mark progress of your service.',
      research_and_data_sharing: 'Research and Data Sharing',
      request_assessments: 'Request assessment(s)',
      retake_full_questionnaire: 'Retake Full Questionnaire',
      retake_questionnaire: 'Retake questionnaire',
      retake_summary_questionnaire: 'Retake Summary Questionnaire',
      retaking_the_full_questionnaire_resets_your_baseline_across_all_domains:
        'Retaking the Full Questionnaire resets your baseline across all domains.',
      save: 'Save',
      select_change_focus_areas: 'Select / change focus areas',
      send_an_email_with_a_list_of_assessments_to_be_completed:
        'Send an email with a list of assessments to be completed.',
      summary_questionnaire: 'Summary questionnaire',
      thank_you_your_selection_was_recorded:
        'Thank you, your selection was recorded.',
      view_requested_assessments: 'View requested assessment(s)',
      view_requested_assessments_from_your_clinician_and_review_previous_results:
        'View requested assessment(s) from your clinician and review previous results.',
      you_can_find_these_in_the_requested_assessments_under_the_common_tasks_section_on_your_dashboard:
        'You can find these in the Requested assessment(s) under the Common tasks section on your dashboard.',
      you_can_invite_a_support_person_to_answer_some_questions_about_your_overall_health_and_well_being:
        'You can invite a support person to answer some questions about your overall health and well-being',
      your_clinician_has_requested_you_to_update_some_questionnaires:
        'Your clinician has requested you to update some questionnaires.',
      your_health_priorities: 'Your health priorities',
      your_service_participates_in_the_following_research_programs_to_develop_a_greater_depth_of_understanding_of_peoples_needs_your_data_is_anonymous_to_everyone_other_than_you_and_your_service_provider_contributing_your_identified_data_is_completely_voluntary_and_will_not_affect_your_clinical_care:
        "Your service participates in the following research program(s) to develop a greater depth of understanding of people's needs. Your data is anonymous to everyone other than you, and your service provider. Contributing your identified data is completely voluntary and will not affect your clinical care.",
    },
    feedback: {
      contact_us: 'Contact us',
      description_is_required: 'Description is required',
      description: 'Description',
      for_privacy_purposes_if_reporting_on_behalf_of_an_individual_please_do_not_include_any_sensitive_and_personal_information_of_the_individual_user_eg_email_address_or_user_name_innowell_will_be_responsible_for_responding_to_your_query_any_followup_with_the_individual_thereafter_should_be_managed_through_you_and_your_service:
        'For privacy purposes, if reporting on behalf of an individual, please do not include any sensitive and personal information of the individual user (e.g. email address or user name). Innowell will be responsible for responding to your query. Any follow-up with the individual thereafter should be managed through you and your service.',
      let_us_know_how_we_can_help: 'Let us know how we can help',
      please_include_as_much_information_as_possible:
        'Please include as much information as possible',
      please_select: '--Please Select--',
      send: 'Send',
      subject_is_required: 'Subject is required',
      subject: 'Subject',
      topic_is_required: 'Topic is required',
      topic: 'Topic',
      what_if_i_am_reporting_on_behalf_of_an_individual:
        'What if I am reporting on behalf of an individual?',
      your_feedback_is_important_to_us_it_helps_us_to_continue_improving_how_the_innowell_platform_works_best_for_you:
        'Your feedback is important to us. It helps us to continue improving how the Innowell Platform works best for you.',
    },
    health: {
      browse_care_options: 'Browse care options',
      click_on_a_health_card_to_find_fact_sheets_browse_a_number_of_care_options_and_review_your_results:
        'Click on a health card to find fact sheets, browse a number of care options and review your results',
      health_priorities: 'Health Priorities',
      latest_results: 'Latest Results',
      retake_questionnaire: 'Retake questionnaire',
      save: 'Save',
      select_health_domains: 'Select Health Domains',
      select_health_priorities: 'Select Health Priorities',
      select_up_to_3_domains_to_focus_on_to_improve_your_overall_wellbeing:
        'Select up to 3 domains to focus on to improve your overall well-being.',
      these_are_the_focus_areas_to_improve_your_overall_wellbeing:
        'These are the focus areas to improve your overall wellbeing',
      update_health_priorities: 'Update Health Priorities',
      update: 'Update',
      view_answers: 'View answers',
      view_assessment_library: 'View assessment library',
      you_currently_have_no_health_priorities_selected:
        'You currently have no Health Priorities selected.',
    },
    hooks: {
      conditionalFormulaRangeDescriptionHook: {
        skipReasons: {
          irrelevant:
            'You chose to skip this question set because you preferred not to answer.',
          later: 'You chose to skip and do this question set at a later time.',
          with_clinician:
            'You chose to skip and do this question set with your clinician. This request has been shared with them.',
          with_support:
            'You chose to skip and do this question set with a support person.',
        },
        skipped: 'Skipped',
        skipReasonSuffix:
          'To get a result for this health card you can update your answers anytime.',
        unanswered: 'Unanswered',
        unAnsweredDescription:
          'The health assessment for this section is not complete.',
      },
      onboardingStepsHook: {
        personalDetailsLabel: 'Tell us a bit about yourself',
        researchAndDataSharingLabel: 'Research and data sharing',
        initialQuestionnaireLabel: 'Initial questionnaire',
        viewResultsLabel: 'View results',
      },
    },
    insights: {
      baseline_scores: 'Baseline Scores',
      changes_between_baseline_ratings_and_latest_ratings_by_domain:
        'Changes between baseline ratings and latest ratings by domain.',
      clinician_sign_ins: 'Clinician Sign Ins',
      domain_ratings_have_been_normalised_into_a_standard_developed_by_innowell:
        'Domain ratings have been normalised into a standard developed by Innowell.',
      individual_onboarding: 'Individual Onboarding',
      individual_sign_ins: 'Individual Sign Ins',
      initial_domain_ratings_recorded_during_the_selected_time_period:
        'Initial domain ratings recorded during the selected time period.',
      insights: 'Insights',
      latest_ratings_must_fall_within_selected_time_frame_to_be_displayed_domains_that_have_only_a_baseline_rating_have_been_excluded_from_this_chart:
        'Latest ratings must fall within selected time frame to be displayed. Domains that have only a Baseline rating have been excluded from this chart.',
      no_data_available_for_this_period: 'No data available for this period.',
      no_domains_with_a_baseline_score_can_be_found_within_thin_perion:
        'No domains with a baseline score can be found within this period.',
      note_percentages_have_been_rounded_to_whole_numbers:
        'Note: Percentages have been rounded to whole numbers.',
      number_of_clinician_sign_ins: 'Number of clinician sign ins',
      number_of_individual_sign_ins: 'Number of individual sign ins',
      recovery_trajectories: 'Recovery Trajectories',
      recovery_trajectory_can_only_be_calculated_with_at_least_two_results_no_domains_with_a_latest_result_found_within_this_period:
        'Recovery Trajectory can only be calculated with at least two results. No domains with a latest result found within this period.',
      stb_escalations: 'STB Escalations',
      summary_questionnaire: 'Summary Questionnaire',
      this_graph_visualises_the_number_of_clinician_sign_ins_over_the_specified_period_helping_to_track_employee_engagement_and_activity_on_the_platform_the_graph_will_break_down_the_sign_ins_based_on_the_number_of_times_each_clinician_signed_in:
        'This graph visualises the number of clinician sign ins over the specified period, helping to track employee engagement and activity on the platform. The graph will break down the sign ins based on the number of times each clinician signed in.',
      this_graph_visualises_the_number_of_individual_sign_ins_over_the_specified_period_helping_to_track_user_engagement_and_activity_on_the_platform_the_graph_will_break_down_the_sign_ins_based_on_the_number_of_times_each_individual_signed_in:
        'This graph visualises the number of individual sign ins over the specified period, helping to track user engagement and activity on the platform. The graph will break down the sign ins based on the number of times each individual signed in.',
      this_graph_visualises_the_number_of_individuals_who_have_successfully_completed_the_onboarding_process_over_the_specified_period:
        'This graph visualises the number of individuals who have successfully completed the onboarding process over the specified period.',
      this_graph_visualises_the_number_of_suicidal_thoughts_and_behaviours_notifications_over_the_specified_period:
        'This graph visualises the number of Suicidal Thoughts and Behaviours notifications over the specified period.',
      this_graph_visualises_the_number_of_summary_questionnaires_that_have_been_completed_over_the_specified_period_the_top_line_will_show_the_total_number_of_questionnaires_completed_and_the_bottom_line_will_show_the_total_number_of_unique_individuals_that_completed_those_questionnaires:
        'This graph visualises the number of Summary Questionnaires that have been completed over the specified period. The top line will show the total number of questionnaires completed and the bottom line will show the total number of unique individuals that completed those questionnaires.',
      try_changing_the_date_range_using_the_date_selector_at_the_top_of_the_page:
        'Try changing the date range using the date selector at the top of the page.',
      welcome_to_your_insights_page_we_ll_be_updating_it_regularly_for_you_with_new_features_so_if_there_are_metrics_you_d_like_to_see_or_you_have_questions_let_us_know_at:
        'Welcome to your Insights page. We’ll be updating it regularly for you with new features, so if there are metrics you’d like to see or you have questions, let us know at',
    },
    onboarding: {
      about_you: 'About you',
      all_answers_are_secure_and_strictly_confidential:
        'All answers are secure and strictly confidential.',
      and: 'and',
      back: 'Back',
      benefits: 'Benefits',
      by_answering_these_questions_it_helps_you_and_your_provider_better_understand_you:
        'By answering these questions, it helps you and your provider better understand you.',
      completed: 'Completed',
      confirm_password: 'Confirm password',
      continue: 'Continue',
      create_password: 'Create Password',
      enter_a_password: 'Enter a password',
      enter_a_username: 'Enter a username',
      enter_your_first_name_and_surname: 'Enter your first name and surname',
      enter_your_name: 'Enter your name',
      first_name_and_surname: 'First Name and Surname',
      great_job: 'Great job',
      i_understand_and_agree_with_the: 'I understand and agree with the',
      if_you_skip_some_questions_you_can_update_them_later_in_your_profile_settings:
        'If you skip some questions, you can update them later in your profile settings.',
      is_not_unique: 'is not unique',
      legal_name_is_required: 'Legal name is required',
      loading: 'Loading',
      name_must_contain_at_least_a_first_name_and_a_surname:
        'Name must contain at least a first name and a surname.',
      of: 'of',
      password_must_be_at_least_8_characters:
        'Password must be at least 8 characters',
      password_must_be_at_most_20_characters:
        'Password must be at most 20 characters',
      password_must_contain_a_mix_of_numbers_and_letters:
        'Password must contain a mix of numbers and letters',
      password_must_contain_a_mix_of_upper_and_lower_case_letters:
        'Password must contain a mix of upper and lower case letters',
      password_must_match: 'Password must match',
      password: 'Password',
      preferred_name_is_required: 'Preferred name is required',
      preferred_name: 'Preferred Name',
      privacy_policy: 'privacy policy',
      question: 'Question',
      remember_you_can_edit_your_information_at_any_time_from_the_profile_settings_in_your_main_menu:
        'Remember, you can edit your information at any time from the profile settings in your main menu',
      repeat_password: 'Repeat password',
      save: 'Save',
      setup_your_innowell_profile_as_everyone_comes_with_a_unique_background:
        'Setup your Innowell profile, as everyone comes with a unique background.',
      skip: 'Skip',
      sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again:
        "Sorry, an error occurred with our server. We've notified our team so that they can address it. Please try again.",
      start: 'Start',
      tell_us_a_bit_about_yourself: 'Tell us a bit about yourself',
      terms_and_conditions: 'terms and conditions',
      this_is_a_short_set_of_questions_before_the_full_questionnaire_that_should_only_take_you_a_few_minutes_to_complete:
        'This is a short set of questions, before the full questionnaire, that should only take you a few minutes to complete.',
      unable_to_render_form_without_questionnaire:
        'Unable to render form without Questionnaire',
      unknown_page: 'Unknown page',
      username: 'Username',
      what_is_your_preferred_name: 'What is your preferred name?',
      you_can_skip_some_of_these_questions_if_you_dont_feel_comfortable_answering_them:
        'You can skip some of these questions if you don’t feel comfortable answering them.',
      you_must_agree_with_the_privacy_policy_and_terms_and_conditions:
        'You must agree with the privacy policy and terms and conditions',
      your_profile: 'Your Profile',
      your_session_has_now_timed_out_click_here_to_sign_in_again:
        'Your session has now timed out. Click here to sign in again.',
      your_session_will_timeout_soon_click_here_to_reload_and_avoid_having_to_sign_in_again:
        'Your session will timeout soon. Click here to re-load and avoid having to sign in again.',
    },
    rootPage: {
      userNotificationsComponent: {
        dismiss: 'Dismiss',
      },
    },
    scoreCard: {
      activity_result: 'Activity Result',
      are: 'are',
      clinician: 'clinician',
      get_started: 'Get started',
      have: 'have',
      has: 'has',
      in_bed: 'In Bed',
      in_progress: 'In Progress',
      is: 'is',
      link: 'Link',
      my_result: 'My Result',
      no_results_for_this_time_period: 'No results for this time period',
      requested: 'Requested',
      requested_support: 'requested support',
      request_this: 'Request this',
      sleep_result: 'Sleep Result',
      started: 'started',
      steps_over_goal: 'Steps over goal',
      support: 'Support',
      supporting: 'Supporting',
      their: 'their',
      this_care_option_requires_your_clinicians_support:
        "This care option requires your clinician's support",
      total_steps: 'Total steps',
      unnamed_user: 'Unnamed User',
      visit: 'Visit',
      waiting_for: 'Waiting for',
      you: 'you',
      your: 'your',

      currentResultCardForumulaPage: {
        ago: 'ago',
        currentResultHeading: 'Current Result:',
        defaultDescription:
          'To find out your score simply complete the questionnaire.',
        lastUpdated: 'Last updated:',
        unanswered: 'Unanswered',
      },
      currentResultCardPage: {
        moreInformation: 'More information about',
        retakeQuestionnaire: 'Retake questionnaire',
      },
      scoreCardsCareOptionsPage: {
        all: 'All',
        careOptionsDescription:
          'Choose from the self-care options or request an option with a clinician.',
        careOptionsHeading: 'Care Options',
        selfCare: 'Self Care',
        workWithClinician: 'Work with Clinician',
      },
      scoreCardsChangeLogPage: {
        asOf: 'As of',
        wasUpdated: 'was updated.',
        learnMore: 'Learn more.',
      },
      scoreCardsNotificationsPage: {
        flashMessage: 'Thank you, your input was recorded.',
        linkText: 'notification',
        registeredIndividual: 'This individual has registered a',
        selectCareOption: 'Please select a care option and then submit',
        submitButton: 'Submit',
        takeAction: 'Please take action.',
      },
      scoreCardsPreviousAnswersPage: {
        answeredBy: 'Answered by',
        answeredOn: 'Answered on',
        completed: 'Completed',
        notSubmitted: 'Not Submitted',
        previousAnswersHeading: 'Previous answers',
        questionSet: 'Question Set',
        rating: 'Rating',
        results: 'Results',
        seeAll: 'See all',
        skipped: 'Skipped',
        status: 'Status',
      },
    },
    shared: {
      no_results_found: 'No results found',
      activityLogComponent: {
        activityLogChangeSet: {
          added: 'Added',
          removed: 'Removed',
        },
        activityLogCardContainer: {
          noRecentActivity: 'No Recent Activity',
        },
        recentActivityText: 'Recent Activity',
      },
      activityPanelComponent: {
        notesText: 'Notes',
        recentActivityText: 'Recent Activity',
      },
      answerSetComponent: {
        notSubmitted: 'Not Submitted',
        submittedAt: 'Submitted at:',
      },
      answerTableComponent: {
        answerColumn: 'Answer',
        questionColumn: 'Question',
      },
      chartHeaderComponent: {
        backToDashboard: 'Back to dashboard',
        dashboard: 'Dashboard',
        healthHistory: 'Health History',
        profile: 'Profile',
      },
      clinicianDropdownComponent: {
        addNote: 'Add note',
        addNoteHeading: 'Add Note',
        giveInputText: 'Give Input',
        toolTipText: 'Actions',
        viewHealthHistory: 'View health history',
        viewProfile: 'View profile',
      },
      clinicianNoteComponent: {
        modalHeading: 'Create Note',
        noClinicianNote: 'No Clinician Notes',
        saveButton: 'Save',
        textFieldPlaceholder: 'Enter note',
      },
      connectedTenantsPage: {
        cliniciansHeading: 'Clinicians',
        connectedOrganizationHeading: 'Connected organisations and access',
        manageAccessDescription:
          'To manage access to your data please contact the relevant service',
        organizationsHeading: 'Organisations',
        workingWithOrganizations:
          'On this page you can see the organisations and clinicians that you are working with',
      },
      dataGridRowFooterContentComponent: {
        copyMessageFailed: 'Copy failed.',
        copyMessageSuccess: 'Copied to clipboard.',
        helpRequested: 'Help Requested',
        lastSeenFormat: 'ago',
        group: {
          one: {
            dob: 'Date of Birth',
            email: 'Email Address',
            preferredName: 'Preferred Name',
          },
          two: {
            initialQuestionnaire: 'Initial Questionnaire',
            lastActive: 'Last active',
            medicalDirectorID: 'Medical Director ID',
          },
        },
      },
      enterPasswordPage: {
        confirmNewPassword: 'Confirm New Password',
        confirmNewPasswordPlaceholder: 'Repeat password',
        newPassword: 'New Password',
        newPasswordPlaceholder: 'Enter new password',
      },
      healthHistoryPage: {
        notificationHistory: 'Notifications history',
        downloadReportTaskButton: {
          reportLabel: 'Report',
        },
        myEscalationsTable: {
          dateTime: 'Date / Time',
          healthCard: 'Health Card',
        },
        healthHistory: {
          unanswered: 'Unanswered',
        },
      },
      errorPage: {
        backButton: 'Back to Home',
        pageDescription: 'Error code: {{errorCode}}',
        '404PageHeading': "We can't find this page",
        genericPageHeading: 'Oops!',
        '500ErrorDescrption':
          'Something went wrong. Please try again later. If you need immediate help, please contact your service or send us a support email.',
        '502ErrorDescrption':
          "Something's gone wrong, but it's likely temporary. Please wait a few moments and try refreshing the page or come back again later. If you need immediate help, please contact your service or send us a support email.",
        '504ErrorDescrption':
          'Sorry, this page is taking way too long to load. Please wait a few moments and try refreshing the page or come back again later. If you need immediate help, please contact your service or send us a support email.',
        pageHeading: 'Error',
      },
      helpNowButtonComponent: {
        call: 'Call',
        chat: 'Chat',
        modalHeading: 'Need Help Now?',
        needHelpLong: 'I need help now',
        needHelpShort: 'Need help',
        website: 'Website',
      },
      fitbitPages: {
        fitbitConnectActivity: {
          and: 'and',
          bulletPoints: {
            one: 'Track your activity and sleep progress.',
            two:
              'View your Fitbit data in the context of your health and wellbeing',
            three: 'Get real-time updates via your dashboard',
          },
          description:
            'You can always disconnect / re-connect at any time. Here is more information on Innowell’s',
          interestedButton: 'Yes, I want to connect',
          modalHeading:
            'Benefits of connecting Fitbit to the Innowell Platform',
          notInterestedButton: 'No, not interested',
          privacyPolicy: 'privacy policy',
          termsAndConditions: 'terms and conditions',
        },
        fitbitConnectionCard: {
          connectFitBitText: 'Connect your Fitbit',
          description:
            'We know there is an important link between fitness, exercise, sleep and mental health. This is the reason why Innowell has linked with Fitbit.',
          disconnectAriaLabel: 'Disconnect your Fitbit',
          disconnectButtonText: 'Disconnect',
        },
        fitbitDisconnectActivity: {
          cancelButton: 'Cancel',
          description:
            'Syncing your fitbit with the Platform allows you to view your health and wellbeing information in one place.',
          disconnectButtonText: 'Disconnect',
          modalHeading: 'Are you sure you would like to disconnect?',
        },
      },
      loadMoreComponent: {
        loadMore: 'Load More',
      },
      releaseWarningBannerComponent: {
        clickHere: 'Click here',
        newVersionTextOne: 'A new version of the app has been released.',
        please: 'Please',
        reloadMessage: 'to reload and avoid errors.',
      },
      requestAssessmentButtonComponent: {
        assessments: 'Assessments',
        requestAssessmentButtonAria: 'request assessment button',
        requestedAssessments: 'Requested Assessments',
      },
      researchDataSharingPage: {
        contributingMessage:
          'Contributing your de-identified data is completely voluntary and you can change your mind at any time. Furthermore, your decision whether or not to have your de-identified data used for research purposes will not affect your standard clinical care, or your current or future relationship with your service provider, or health professional, or Innowell.',
        forMoreInformation:
          'For more information about how Innowell uses data please see,',
        lastModified: 'Last modified: June 2022',
        personalInformationDescription:
          'Your personal information will be always stored in The Innowell Platform and only your de-identified data will be used for research purposes. The de-identified data will be extracted, used for statistical analysis, and to improve the overall effectiveness of The Innowell Platform.',
        personalInformationMessage:
          'Your personal information is de-identified and no one can link your name, email address or date of birth to your health information.',
        researchDataSharingHeading: 'Research Data Sharing',
        thankYouMessage:
          'Thank you for sharing your de-identified data with Innowell.',
      },
      readOnlyModePage: {
        contactService: 'Please contact your service to change these settings.',
        readOnlyMode: 'You are in read only mode.',
      },
      rolePage: {
        dataPolicy: 'data policy',
        description:
          "Your service participates in the following research program(s) to develop a greater depth of understanding of people's needs. This information is used to improve the delivery of person centred care. Contributing your de-identified data is completely voluntary and will not affect your clinical care. Read our",
        loadingSpinnerMessage: 'Changing Roles',
        moreInformation: 'for more information on how your data will be used.',
        researchPageHeading: 'Research and data sharing',
        suspenseLoadingMessage: 'ResearchDataSharingPage',
        flashMessage: 'Thank you, your selection was recorded.',
      },
      staffDownloadReportButtonComponent: {
        continueButton: 'Continue',
        modalDescription:
          'Please ensure you take appropriate steps to protect the privacy of this document in accordance with the clinical governance requirements of your service.',
        modalHeading: 'Results download - security warning',
        reportButton: 'Report',
      },
      staffNavigationTabs: {
        clinicians: 'Clinicians',
        employees: 'Employees',
        individuals: 'Individuals',
        managers: 'Managers',
        notifications: 'Notifications',
      },
      summaryGraphComponent: {
        healthSnapshot: 'Health Snapshot',
        healtyLabel: 'Healthy',
        moveRangeBack: 'Move time range back',
        moveRangeForward: 'Move time range forward',
        seeMoreDetails: 'See more details',
      },
      supportPersonModalComponent: {
        addAnotherPerson: 'Add another person',
        anotherInvite: 'Another',
        description:
          'Different perspectives can help add more information to your health profile. They will receive an email invite to answer a questionnaire about your health and well being.',
        inviteText: 'Invite',
        oneInvite: 'a',
        paragraphOne:
          'They will not be able to view any of your health information.',
        paragraphTwo: 'If you remove a support person they won’t be notified.',
        supportPersonText: 'Support Person',
        yourSupportPersonHeading: 'Your Support Person',
      },
      sessionTimeoutWarningComponent: {
        sessionTimeoutMessage:
          'Your session has expired. Please sign in again to continue.',
      },
      supportTaskButtonComponent: {
        ariaLabelText: 'add support person',
        headingText: 'Add Support Person',
        paragraphOne:
          'Different perspectives can help add more information to your health profile. They will receive an email invite to answer a questionnaire about your health and well being.',
        paragraphTwo:
          'They will not be able to view any of your health information. If you remove a support person they won’t be notified.',
        supportForm: {
          emailLabel: 'Email',
          emailPlaceholder: 'Add your support person’s email',
          inviteButtonText: 'Invite support person',
          nameLabel: 'Name',
          namePlaceholder: 'Add your support person’s name',
          relationship: 'Relationship',
          validations: {
            nicknameValidation: 'Support Person’s Name is required',
            relationshipValidation: 'Invalid relationship type',
            supportEmailRequired: 'Support Person’s Email is required',
            supportEmailValidation:
              'Support Person’s Email must be a valid email',
          },
        },
        supportInfo: {
          ago: 'ago',
          email: 'Email',
          emailText: 'EMAIL',
          getSupportInput: 'Get support',
          invitationSentText: 'INVITE SENT TO:',
          lastCompleted: 'Last Completed:',
          lastRequested: 'Last Requested',
          nameLabel: 'Name',
          reinviteSupport: 'Re-invite support',
          relationship: 'Relationship',
          removeButton: 'Remove',
          removedMessageDescription:
            'They will not be notified of being removed.',
          removedMessageText: 'HAS BEEN REMOVED',
          requestHistory: 'History of requests',
          sentTo: 'SENT TO',
          supportRequestText: 'SUPPORT REQUEST',
        },
      },
      trackableChartToggleComponent: {
        checkoutMessage: 'Check out the new',
        new: 'NEW!',
        oldDashboard: 'to old summary dashboard.',
        switchBack: 'Switch Back',
        trendGraphs: 'trend graphs',
      },
      userQuestionnaireComponent: {
        benefitsOfInnowellFour:
          'Complete the questionnaire in one sitting, preferred, or come back over time if necessary.',
        benefitsOfInnowellHeading: 'Benefits of Innowell',
        benefitsOfInnowellOne:
          'Results of the questionnaire are shared with your clinician to help support your mental health care.',
        benefitsOfInnowellThree:
          'Each health area has associated activities, called Care Options, that provide tools for improvement.',
        benefitsOfInnowellTwo:
          'Immediate access to your results and a holistic overview of your health and wellbeing.',
        importantNoteOne: 'The Innowell platform is not a diagnostic tool.',
        importantNoteTwo:
          'Your information is secure and strictly confidential.',
        importantNotesHeading: 'Important notes',
        startButton: 'Start',
      },
      unauthorisedPage: {
        backButton: 'Back to Home',
        pageDescription:
          'You are not authorised to access this page. For access, please contact your supervisor or systems admin.',
        pageHeading: 'Unauthorised Access',
      },
      staff: {
        staffDashboardSelectionComponent: {
          ageAndGender: 'AGE / GENDER',
          backToListAriaLabel: 'Back to Individual list',
          email: 'EMAIL',
          filterAriaLabel: 'filter',
          individualInfo: 'Individual Info',
          initialScore: 'INITIAL SCORE',
          lastActive: 'LAST ACTIVE',
          moreInfo: 'More Info',
          switchIndividual: 'Switch Individual',
        },
      },
      hooks: {
        usePersonalDetailsHook: {
          copyMessageFailed: 'Copy failed.',
          copyMessageSuccess: 'Copied to clipboard.',
          lastSeenFormat: 'ago',
        },
      },
    },
    staff: {
      employee: {
        staffEmployeesTableComponent: {
          emailAddress: 'Email address',
          employeeName: 'Employee name',
          role: 'Role(s)',
        },
        staffEmployeesMultiSelectComponent: {
          emptyStateText:
            'No common actions. Ensure selected rows have a common role.',
          remove: 'Remove',
          resend: 'Resend',
          removeRole: 'Remove Role',
          resendInvitation: 'Resend Invitation',
          role: 'Role',
          invitation: 'Invitation',
        },
        staffEmployeesRemoveEmployeesModalComponent: {
          removeRole: 'Remove Role',
          bodyTextOne: 'You are about to remove',
          from: 'from',
          role: 'role',
          employee_one: 'employee',
          employee_other: 'employees',
          confirmationText: 'Are you sure you want to continue?',
          buttonTextNo: 'No',
          buttonTextYes: 'Yes, remove role',
        },
        staffEmployeesResendEmployeesModalComponent: {
          removeRole: 'Resend Role Invitations',
          bodyTextOne: 'You are about to resend',
          for: 'for',
          invitations: 'invitations',
          employee_one: 'employee',
          employee_other: 'employees',
          confirmationText: 'Are you sure you want to continue?',
          buttonTextNo: 'No',
          buttonTextYes: 'Yes, resend invitations',
        },
      },
      individual: {
        assignCliniciansModalPage: {
          assign: 'Assign',
          clinicians: 'Clinicians',
          loading: 'Loading',
        },
        dischargeModalPage: {
          bodyTextOne: 'You are about to discharge',
          bodyTextThree: 'including those with unresolved notifications.',
          bodyTextTwo: 'Please note, all individuals will be discharged',
          buttonTextNo: 'No',
          buttonTextYes: 'Yes, discharge',
          confirmationText: 'Are you sure you want to continue?',
          discharge: 'Discharge',
          individual_one: 'individual',
          individual_other: 'individuals',
        },
        individualsTablePage: {
          assignClinicians: 'Assign Clinicians',
          discharge: 'Discharge',
          reinvite: 'Re-invite',
          remove: 'Remove',
          requestAssessments: 'Request Assessments',
          resendInvitations: 'Resend Invitations',
          tinyLoaderMessage: 'Loading',
        },
        reinviteIndividualsModalPage: {
          bodyTextOne: 'You are about to re-invite',
          buttonTextNo: 'No',
          buttonTextYes: 'Yes, re-invite',
          confirmationText: 'Are you sure you want to continue?',
          reinviteSent: 'Re-invites Sent',
          reinvite: 'Re-invite',
          individual_one: 'individual',
          individual_other: 'individuals',
          invitation_one: 'invitation',
          invitation_other: 'invitations',
          summaryText:
            'Re-invites sent. Please allow around 5 minutes for re-invites to reach individuals.',
          sent: 'sent',
          failed: 'failed',
          done: 'Done',
          failedSummaryText:
            'Following invitations may have failed because they are currently active in another tenant.',
        },
        resendInvitationsModalPage: {
          bodyTextOne: 'You are about to resend',
          buttonTextNo: 'No',
          buttonTextYes: 'Yes, resend',
          confirmationText: 'Are you sure you want to continue?',
          invitationSent: 'Invitations Sent',
          resend: 'Resend',
          individual_one: 'individual',
          individual_other: 'individuals',
          invitation_one: 'invitation',
          invitation_other: 'invitations',
          invitationTo_one: 'an invitation to',
          invitationTo_other: 'invitations to',
          summaryText:
            'Invitations sent. Please allow around 5 minutes for re-invites to reach individuals.',
          sent: 'sent',
          failed: 'failed',
          done: 'Done',
          failedSummaryText:
            'Following invitations may have failed because they are currently active in another tenant.',
        },
        removeIndividualsModalPage: {
          bodyTextOne: 'You are about to remove',
          buttonTextNo: 'No',
          buttonTextYes: 'Yes, remove',
          confirmationText: 'Are you sure you want to continue?',
          remove: 'Remove',
          individual_one: 'individual',
          individual_other: 'individuals',
        },
        requestAssessmentModalPage: {
          requestAssessments: 'Request Assessments',
          sendRequest: 'Send request(s)',
          placeholder: 'Search for questionnaire...',
          selectQuestionnaire: 'Select questionnaire(s) to request',
          loading: 'Loading',
        },
        staffIndividualsTableFooterPage: {
          copyMessageFailed: 'Copy failed.',
          copyMessageSuccess: 'Copied to clipboard.',
          helpRequested: 'Help Requested',
          lastSeenFormat: 'ago',
          group: {
            one: {
              dob: 'Date of Birth',
              email: 'Email Address',
              preferredName: 'Preferred Name',
            },
            two: {
              initialQuestionnaire: 'Initial Questionnaire',
              lastActive: 'Last active',
            },
          },
        },
        standardIndividualsTableComponentPage: {
          clinicians: 'Clinicians',
          patientName: 'Patient name',
          status: 'Status',
        },
      },
      clinician: {
        clinicianTablePage: {
          assignIndividuals: 'Assign individuals',
          tinyLoaderMessage: 'Loading',
        },
        assignIndividualsModalPage: {
          assign: 'Assign',
          bodyTextOne: 'You are about to assign',
          bodyTextTwo: 'to the selected clinicians',
          bodyTextThree: 'could not be assigned',
          bodyTextFour:
            'Following invitations may have failed because one or more clinicians may not yet be active',
          assigned: 'Assigned',
          continue: 'Continue',
          clinician_one: 'clinician',
          clinician_other: 'clinicians',
          confirmationText: 'Are you sure you want to continue?',
          individuals: 'Individuals',
          individual_one: 'individual',
          individual_other: 'individuals',
          loading: 'Loading',
          summaryText:
            'assigned. Please allow around 5 minutes for assignments to complete.',
          done: 'Done',
          buttonTextNo: 'No',
          buttonTextYes: 'Yes, assign',
        },
      },
      esclation: {
        staffEscalationsTableDataGridPage: {
          actionedBy: 'Actioned by',
          and: 'and',
          careOption: 'Care option actioned',
          createdDate: 'Date',
          healthCard: 'Health Card',
          more: 'more...',
          patientName: 'Patient name',
          primaryClinician: 'Primary clinician',
          reason: 'Reason',
          resolvedAt: 'Date',
        },
      },
    },
    supporting: {
      age_range: 'Age range',
      call: 'Call',
      chat: 'Chat',
      description: 'Description',
      if_you_or_someone_you_know_need_immediate_help_call:
        'If you or someone you know needs immediate help, call',
      need_help_now: 'Need Help now?',
      now: 'now.',
      phone_hours: 'Phone hours',
      talk_to_someone: 'Talk to Someone',
      text: 'Text',
      twenty_four_hours_a_day_seven_days_a_week:
        '24 hours a day, 7 days a week',
      website: 'Website',
      supportingPage: {
        supportingSupporteeInfoWrapper: {
          contactOptionOne:
            'There’s no right or wrong way to go about supporting someone, often the first step is having an open conversation. While this can be difficult to do, check out th',
          contactOptionTwo:
            'website to help you plan better for these conversations.',
          firstContentOne:
            'This questionnaire will help provide an additional perspective on the health and well being of the person you are supporting.',
          firstContentTwo:
            'Your answers will only be seen by the person you are supporting and their connected clinicians.',
          for: 'for',
          infoCardTitleOne: 'Important information',
          infoCardTitleTwo: 'What else can I do now?',
          or: 'or',
        },
        supportingSupporteeQuestionnaire: {
          readyToSubmitButtonLabel: 'Ready to submit',
          startButtonLabel: 'Start questionnaire',
          submitteduttonLabel: 'Submitted',
        },
        supportingSupporteeQuestionnairesList: {
          heading:
            "You've been requested to answer the following questionnaire(s) for...",
          summaryQuestionnaire: 'Summary Questionnaire',
        },
        supportingSupporteeQuestionnaireSubmissionComponent: {
          submitButton: 'Submit',
          submittingButton: 'Submitting...',
          summaryQuestionnaire: 'Summary Questionnaire',
        },
        supportingSupporteeQuestionnaireSubmissionPage: {
          albertaHealthServices: 'Alberta Health Services',
          callNumber: ', or call 519-646-6000, ext. 65196',
          chatForLife: 'Chats for Life',
          checkIn: 'Check-in',
          description:
            'There’s no right or wrong way to go about supporting someone, often the first step is having an open conversation. While this can be difficult to do, check out the',
          kidsHelpPhone: 'Kids Help Phone',
          oftenAnswerQuestionnaire:
            'How often should you answer this questionnaire?',
          oftenAnswerQuestionnaireDescription:
            'You can re-answer the questionnaire at anytime by logging into the Innowell platform. The person you are caring for can also send an email request for you to update the questionnaire.',
          or: 'or',
          pageHeading:
            'Thank you for successfully completing this questionnaire.',
          questionnaireDescription:
            'This questionnaire will help to provide different perspectives on the health and well being of the person you are caring for.',
          seeYourAnswerDescription:
            'Your answers will be seen by the person you are caring for and their connected clinicians.',
          toHelpWithConvo: 'to help you plan better for these conversations.',
          website: 'website',
          websiteOrContact: 'website, or contact',
          whatElseHeading: 'What else can I do now?',
          whatIsQuestionnaireHeading: 'What is this questionnaire for?',
          WhoSeeYourAnswers: 'Who will see your answers?',
        },
        supportingSupporteeRemoveModal: {
          removeYourSelf: 'Remove yourself as support person',
          closeButton: 'Close',
          removeButton: 'Remove',
        },
        supportingSupporteeSelectionPage: {
          notSupportingHeading: 'You are not supporting anyone',
          welcomeMessage: 'Welcome {{name}} and thank you for your time today!',
        },
      },
    },
    settings: {
      userSettingsPage: {
        manageSettingsHeading: 'Manage your settings',
        profile: 'Profile',
        password: 'Password',
        healthHistory: 'Health History',
        cliniciansAndHealthcareProviders: 'Clinicians and healthcare providers',
        researchAndDataSharing: 'Research and data sharing',
        smsNotifications: 'SMS notifications',
        contactUs: 'Contact us',
      },
      userNotificationPage: {
        descriptionOne:
          'Your mobile number will be used to send you reminders and notifications. You can turn SMS notifications on or off anytime.',
        descriptionTwo:
          'Innowell will never share your mobile number. If you want more information on your data please see our',
        notificationsSettingsLoadingMessage: 'Notifications Settings',
        privacyPolicy: 'privacy policy.',
        smsNotificationsHeading: 'SMS notifications',
      },
      userSettingsUserFieldPage: {
        emailAddressTitle: 'Email address',
        emailTitle: 'Email',
        legalNameTitle: 'Legal name',
        legalNameValidation:
          'Legal name must contain at least a first name and a family name.',
        mobileNumber: 'Mobile number',
        mobileNumberDescriptionOne:
          'Your mobile number will be used to send you reminders and notifications. You can turn notifications on or off at any time on `your notifications` page.',
        mobileNumberDescriptionTwo:
          'Innowell will never share your mobile number. If you want more information on your data please see our',
        mobileNumberValidation:
          'Mobile number is 10 digits and starts with 04 e.g. 0426664444',
        phoneNumberPlaceholder: 'e.g. 0426664444',
        phoneNumberTitle: 'Add your mobile number',
        preferredLanguageTitle: 'Preferred Language',
        preferredNameTitle: 'Preferred name',
        privacyPolicy: 'Privacy policy',
        submitEmail: 'Update email',
        submitMobileNumber: 'Update phone number',
        submitPreferredLanguage: 'Update preferred language',
        submitUpdateName: 'Update name',
        submitUpdatePreferredName: 'Update name',
        submitUsername: 'Update username',
        usernameTitle: 'Username',
        usernameUniquenessError: 'Sorry, this username has already been taken',
        whatsYourEmail: 'What is your email?',
        whatsYourLegalName: 'What is your Legal Name?',
        whatsYourPreferredLanguage: 'What is your Preferred Language?',
        whatsYourPreferredName: 'What is your Preferred Name?',
        whatsYourUsername: 'What is your Username?',
      },
      userSettingsPersonalDetailsPage: {
        emailWarning: 'Email change requested, awaiting confirmation.',
        manageInformation:
          'On this page, you can manage your information, including your contact details',
        profileSettingHeading: 'Profile Settings',
      },
      userSettingsPersonalDetailsEditPage: {
        backButton: 'Back',
        updateButton: 'Update',
      },
    },
  },
  fr: {
    assessment: {
      a_quick_check_in_on_mental_health_incorporating_multiple_instruments_and_evaluating_general_health_history_and_wellbeing:
        'Un enregistrement rapide de la santé mentale, intégrant plusieurs instruments et évaluant les antécédents de santé généraux et le bien-être.',
      answer_one: 'Répondre',
      answer_other: 'Réponses',
      answered_by: 'Répondu par',
      answered_on: 'Répondu le',
      answered: 'Répondu',
      assessments_that_dont_apply_to_the_patient_have_been_removed_from_the_list:
        "Les évaluations qui ne s'appliquent pas au patient ont été supprimées de la liste.",
      assessments: 'Évaluations',
      back_to_details: 'Retour aux détails',
      complete_all: '(Complétez tout)',
      completed: 'Complété',
      full_questionnaire: 'Questionnaire complet',
      incomplete: 'Incomplet',
      individual: 'Individuel',
      last_answered: 'Dernière réponse',
      library: 'Bibliothèque',
      no_assessment_request: "Aucune demande d'évaluation trouvée",
      no_results_found: 'Aucun résultat trouvé',
      provide_a_comprehensive_overview_of_mental_health_across_multiple_domains_evaluating_symptoms_functioning_risk_indicators_and_support_systems:
        'Fournir un aperçu complet de la santé mentale dans plusieurs domaines, en évaluant les symptômes, le fonctionnement, les indicateurs de risque et les systèmes de soutien.',
      question_set: 'Ensemble de questions',
      question: 'Question',
      questionnaire: 'Questionnaire',
      rating: 'Notation',
      request_assessments: 'Demander des évaluations',
      requested_assessments: 'Évaluations demandées',
      requested_on: 'Demandé le',
      requested: 'Demandé',
      search_bar: 'Barre de recherche',
      search_for_questionnaire: 'Rechercher un questionnaire...',
      select_a_person: 'Sélectionnez une personne',
      select_person_to_send_a_request_to:
        'Sélectionnez la personne à qui envoyer une demande',
      select_questionnaire_to_request:
        'Sélectionnez le(s) questionnaire(s) à demander',
      send_requests: 'Envoyer des demandes',
      skipped: 'Sauté',
      status: 'Statut',
      summary_questionnaire: 'Questionnaire récapitulatif',
      take_assessment: 'Passer une évaluation',
      unanswered: 'Sans réponse',
      updating_list: 'Mise à jour de la liste',
      view_answers_from: 'Afficher les réponses de',
      view_answers: 'Afficher les réponses',
      out_of: 'de',
      approx: 'environ',
      to_complete: 'compléter',
      secure_and_confidential: 'sécurisé et confidentiel',
      saved_automatically: 'enregistré automatiquement',
      skip: 'Sauter',
      next: 'Suivant',
      submit: 'Soumettre',
      submitting: 'Soumission',
      need_one: 'besoin',
      need_other: 'besoins',
      completing: 'terminer',
      sorry_your_role_is_not_supported_for_questionnaires:
        "Désolé, votre rôle n'est pas pris en charge pour les questionnaires.",
      well_done: 'Bien joué',
      you_are_almost_done: 'Tu as presque fini',
      of_the_initial_questionnaire_completed:
        'du questionnaire initial complété',
      you_can_still_submit_your_answers_and_return_to_the_skipped_incomplete_questions_anytime_or_if_you_prefer_you_can_choose_to_do_them_with_a_support_person_or_your_clinician:
        'Vous pouvez toujours soumettre vos réponses et revenir aux questions sautées/incomplètes à tout moment ou si vous préférez, vous pouvez choisir de les faire avec une personne de soutien ou votre clinicien.',
      your_dashboard_has_sorted_your_results_into_health_cards_so_you_can_focus_on_whats_important_and_explore_a_range_of_care_options_that_help_you_meet_your_health_goals:
        "Votre tableau de bord a classé vos résultats dans des cartes de santé afin que vous puissiez vous concentrer sur ce qui est important et explorer une gamme d'options de soins qui vous aident à atteindre vos objectifs de santé.",
      you_can_discuss_your_results_with_your_clinician_your_clinician_will_work_with_you_through_the_dashboard_to_tailor_treatment_to_your_needs:
        'Vous pouvez discuter de vos résultats avec votre clinicien. Votre clinicien travaillera avec vous via le tableau de bord pour adapter le traitement à vos besoins.',
      skipped_and_incomplete_sections: 'Sections ignorées et incomplètes',
      completed_sections: 'Sections terminées',
    },
    auth: {
      already_have_an_account: 'Vous avez déjà un compte ?',
      back_to_login: 'Retour à la connexion',
      current_password: 'Mot de passe actuel',
      current_password_is_required: 'Le mot de passe actuel est requis',
      email: 'E-mail',
      email_is_a_required_field: "L'e-mail est un champ requis",
      email_is_required: "L'e-mail est requis",
      email_must_be_a_valid_email: "L'e-mail doit être valide",
      enter_old_password: "Entrez l'ancien mot de passe",
      enter_verification_code: 'Entrez le code de vérification',
      forgot_your_password: 'Mot de passe oublié ?',
      get_help_signing_in: "Obtenez de l'aide pour vous connecter.",
      i_cant_set_it_up_now_log_me_out:
        'Je ne peux pas le configurer maintenant, déconnectez-moi',
      i_dont_have_the_code_now_log_me_out:
        "Je n'ai pas le code maintenant, déconnectez-moi",
      if_the_email_address_or_region_provided_is_registered_on_our_system_an_email_with_the_instructions_to_reset_the_password_will_be_sent_shortly_this_password_reset_is_only_valid_for_the_next_6_hours:
        "Si l'adresse e-mail ou la région fournie est enregistrée dans notre système, un e-mail avec les instructions pour réinitialiser le mot de passe sera envoyé sous peu. Cette réinitialisation du mot de passe est valide uniquement pendant les 6 prochaines heures.",
      if_you_do_not_receive_an_email_in_5_minutes_please_check_your_region_setting_and_email_address:
        'Si vous ne recevez pas un e-mail dans les 5 minutes, veuillez vérifier votre région et votre adresse e-mail.',
      invalid_verification_code: 'Code de vérification invalide',
      keep_me_signed_in: 'Rester connecté',
      must_match_password: 'Doit correspondre au mot de passe',
      new_passwords_must_match:
        'Les nouveaux mots de passe doivent correspondre',
      next: 'Suivant',
      password: 'Mot de passe',
      password_confirmation: 'Confirmation du mot de passe',
      password_confirmation_is_required:
        'La confirmation du mot de passe est requise',
      password_is_a_required_field: 'Le mot de passe est un champ requis',
      password_is_required: 'Le mot de passe est requis',
      password_must_be_at_least_6_characters:
        'Le mot de passe doit comporter au moins 6 caractères',
      password_must_be_at_least_8_characters:
        'Le mot de passe doit comporter au moins 8 caractères',
      please_check_your_email: 'Veuillez vérifier votre e-mail',
      please_create_a_new_password: 'Veuillez créer un nouveau mot de passe',
      please_enter_verification_code: 'Veuillez entrer le code de vérification',
      please_enter_your_authenticator_app_verification_code_below:
        "Veuillez entrer le code de vérification de votre application d'authentification ci-dessous.",
      please_enter_your_email_below_to_receive_password_reset_instructions:
        'Veuillez entrer votre e-mail ci-dessous pour recevoir les instructions de réinitialisation du mot de passe',
      qr_code: 'Code QR',
      request_new_invite: 'Demander une nouvelle invitation',
      request_the_link_again: 'Demander à nouveau le lien',
      save: 'Enregistrer',
      scan_the_qr_code_with_your_authentication_app_and_enter_the_verification_code_below:
        "Scannez le code QR avec votre application d'authentification et entrez le code de vérification ci-dessous.",
      secure_your_account: 'Sécurisez votre compte',
      select_your_region: 'Sélectionnez votre région',
      send_instructions: 'Envoyer les instructions',
      sign_in: 'Se connecter',
      sorry_an_error_occured_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again:
        "Désolé, une erreur est survenue avec notre serveur. Nous avons informé notre équipe afin qu'elle puisse la résoudre. Veuillez réessayer.",
      sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_refresh_the_page_before_trying_to_login_again:
        "Désolé, une erreur est survenue avec notre serveur. Nous avons informé notre équipe afin qu'elle puisse la résoudre. Veuillez actualiser la page avant de tenter de vous reconnecter.",
      sorry_we_couldnt_change_your_passowrd:
        "Désolé, nous n'avons pas pu changer votre mot de passe",
      sorry_we_couldnt_change_your_password:
        "Désolé, nous n'avons pas pu changer votre mot de passe",
      sorry_your_new_password: 'Désolé, votre nouveau mot de passe',
      sorry_your_new_password_is: 'Désolé, votre nouveau mot de passe est',
      sorry_your_token: 'Désolé, votre jeton',
      success: 'Succès',
      this_invite_is_invalid: 'Cette invitation est invalide.',
      update_your_password: 'Mettez à jour votre mot de passe',
      use_the_button_below_to_request_a_new_invite_from_your_service:
        'Utilisez le bouton ci-dessous pour demander une nouvelle invitation à votre service.',
      username: "Nom d'utilisateur",
      username_is_a_required_field: "Le nom d'utilisateur est un champ requis",
      welcome_to_the_innowell_platform: 'Bienvenue sur la plateforme Innowell',
      your_password_has_been_updated: 'Votre mot de passe a été mis à jour.',
    },
    careUI: {
      graphsComponent: {
        apply: 'Appliquer',
        cancel: 'Annuler',
        custom: 'Coutume',
        monthly: 'Mensuel',
        signIn: 'se connecter',
        signIns: 'connexionss',
        weekly: 'Hebdomadaire',
        yearly: 'Annuel',
      },
      pageHeaderComponent: {
        backToDashboardButton: 'Retour au tableau de bord',
      },
    },
    dashboard: {
      by_keeping_up_to_date_you_can_track_your_health_and_well_being_over_time:
        'En vous tenant au courant, vous pouvez suivre votre santé et votre bien-être au fil du temps.',
      click_here: 'cliquez ici',
      click_here_to_start: 'Cliquez ici pour commencer',
      click_on_a_health_card_to_find_fact_sheets_browse_a_number_of_care_options_and_review_your_results:
        'Cliquez sur une carte Santé pour trouver des fiches d’information, parcourir un certain nombre d’options de soins et consulter vos résultats.',
      common_tasks: 'Tâches courantes',
      continue: 'Continuer',
      download_a_pdf_of_their_health_history:
        'Téléchargez un PDF de leurs antécédents médicaux.',
      download_health_history_pdf: "Télécharger l'historique de santé PDF",
      finish_questionnaire: 'Terminer le questionnaire',
      finish_the_questionnaire_to_create_a_baseline_of_your_health_and_well_being:
        'Terminez le questionnaire pour créer une base de référence sur votre santé et votre bien-être.',
      for_more_information_on_the_use_of_your_data:
        'Pour plus d’informations sur l’utilisation de vos données,',
      full_questionnaire: 'Questionnaire complet',
      give_input: 'Donner votre avis',
      health_history_pdf: 'Antécédents de santé PDF',
      i_accept: "J'accepte",
      i_decline: 'Je refuse',
      invite_edit_support_person: "Inviter/Modifier une personne d'assistance",
      it_appears_we_dont_have_all_your_answers:
        'Il semble que nous n’ayons pas toutes vos réponses.',
      its_your_history_and_you_can_take_it_with_you:
        "C'est votre histoire et vous pouvez l'emporter avec vous.",
      loading: 'Chargement',
      no_health_cards_found: 'Aucune carte de santé trouvée.',
      out_of: 'de',
      please_ensure_you_take_appropriate_steps_to_protect_the_privacy_of_this_document_in_accordance_with_the_clinical_governance_requirements:
        'Veuillez vous assurer de prendre les mesures appropriées pour protéger la confidentialité de ce document conformément aux exigences de gouvernance clinique.',
      provide_feedback_to_mark_progress_of_your_service:
        'Fournissez des commentaires pour marquer la progression de votre service.',
      research_and_data_sharing: 'Recherche et partage de données',
      request_assessments: 'Demander une ou des évaluations',
      retake_full_questionnaire: 'Reprendre le questionnaire complet',
      retake_questionnaire: 'Reprendre le questionnaire',
      retake_summary_questionnaire: 'Reprendre le questionnaire récapitulatif',
      retaking_the_full_questionnaire_resets_your_baseline_across_all_domains:
        'Reprendre le questionnaire complet réinitialise votre référence dans tous les domaines.',
      save: 'Sauvegarder',
      select_change_focus_areas:
        'Sélectionner/modifier les zones de mise au point',
      send_an_email_with_a_list_of_assessments_to_be_completed:
        'Envoyez un e-mail avec une liste des évaluations à compléter.',
      summary_questionnaire: 'Questionnaire récapitulatif',
      thank_you_your_selection_was_recorded:
        'Merci, votre sélection a été enregistrée.',
      view_requested_assessments: 'Afficher les évaluations demandées',
      view_requested_assessments_from_your_clinician_and_review_previous_results:
        'Consultez les évaluations demandées par votre clinicien et examinez les résultats précédents.',
      you_can_find_these_in_the_requested_assessments_under_the_common_tasks_section_on_your_dashboard:
        'Vous pouvez les trouver dans la ou les évaluations demandées sous la section Tâches courantes de votre tableau de bord.',
      you_can_invite_a_support_person_to_answer_some_questions_about_your_overall_health_and_well_being:
        'Vous pouvez inviter une personne de soutien à répondre à quelques questions sur votre santé et votre bien-être.',
      your_clinician_has_requested_you_to_update_some_questionnaires:
        'Votre clinicien vous a demandé de mettre à jour certains questionnaires.',
      your_health_priorities: 'Vos priorités en matière de santé',
      your_service_participates_in_the_following_research_programs_to_develop_a_greater_depth_of_understanding_of_peoples_needs_your_data_is_anonymous_to_everyone_other_than_you_and_your_service_provider_contributing_your_identified_data_is_completely_voluntary_and_will_not_affect_your_clinical_care:
        'Votre service participe au(x) programme(s) de recherche suivant(s) pour développer une meilleure compréhension des besoins des gens. Vos données sont anonymes pour toute personne autre que vous et votre fournisseur de services. La contribution de vos données identifiées est entièrement volontaire et n’affectera pas vos soins cliniques.',
    },
    feedback: {
      contact_us: 'Contactez-nous',
      description_is_required: 'La description est obligatoire',
      description: 'Description',
      for_privacy_purposes_if_reporting_on_behalf_of_an_individual_please_do_not_include_any_sensitive_and_personal_information_of_the_individual_user_eg_email_address_or_user_name_innowell_will_be_responsible_for_responding_to_your_query_any_followup_with_the_individual_thereafter_should_be_managed_through_you_and_your_service:
        "Pour des raisons de confidentialité, si vous signalez au nom d'un individu, veuillez ne pas inclure d'informations sensibles et personnelles sur l'utilisateur individuel (par exemple, adresse e-mail ou nom d'utilisateur). Innowell sera responsable de répondre à votre demande. Tout suivi avec l'individu devra ensuite être géré par vous et votre service.",
      let_us_know_how_we_can_help:
        'Faites-nous savoir comment nous pouvons vous aider',
      please_include_as_much_information_as_possible:
        "Veuillez inclure autant d'informations que possible",
      please_select: '--Veuillez sélectionner--',
      send: 'Envoyer',
      subject_is_required: 'Le sujet est obligatoire',
      subject: 'Sujet',
      topic_is_required: 'Le sujet est obligatoire',
      topic: 'Sujet',
      what_if_i_am_reporting_on_behalf_of_an_individual:
        "Que faire si je signale au nom d'un individu ?",
      your_feedback_is_important_to_us_it_helps_us_to_continue_improving_how_the_innowell_platform_works_best_for_you:
        'Vos commentaires sont importants pour nous. Ils nous aident à continuer à améliorer la manière dont la plateforme Innowell fonctionne le mieux pour vous.',
    },
    health: {
      click_on_a_health_card_to_find_fact_sheets_browse_a_number_of_care_options_and_review_your_results:
        "Cliquez sur une carte de santé pour trouver des fiches d'information, parcourir plusieurs options de soins et examiner vos résultats",
      latest_results: 'Derniers résultats',
      view_assessment_library: "Voir la bibliothèque d'évaluations",
      browse_care_options: 'Parcourir les options de soins',
      retake_questionnaire: 'Refaire le questionnaire',
      update: 'Mettre à jour',
      update_health_priorities: 'Mettre à jour les priorités de santé',
      view_answers: 'Voir les réponses',
      save: 'Enregistrer',
      select_up_to_3_domains_to_focus_on_to_improve_your_overall_wellbeing:
        "Sélectionnez jusqu'à 3 domaines sur lesquels vous concentrer pour améliorer votre bien-être global.",
      select_health_domains: 'Sélectionner les domaines de santé',
      health_priorities: 'Priorités de santé',
      these_are_the_focus_areas_to_improve_your_overall_wellbeing:
        'Ce sont les domaines sur lesquels se concentrer pour améliorer votre bien-être global',
      you_currently_have_no_health_priorities_selected:
        "Vous n'avez actuellement aucune priorité de santé sélectionnée.",
      select_health_priorities: 'Sélectionner les priorités de santé',
    },
    hooks: {
      conditionalFormulaRangeDescriptionHook: {
        skipReasons: {
          irrelevant:
            'Vous avez choisi de sauter cette série de questions parce que vous avez préféré ne pas répondre.',
          later:
            'Vous avez choisi de sauter et de répondre à cette série de questions plus tard.',
          with_clinician:
            'Vous avez choisi de passer cette étape et de la réaliser avec votre médecin. Cette demande lui a été communiquée.',
          with_support:
            'Vous avez choisi de sauter et de faire cette série de questions avec une personne de soutien.',
        },
        skipped: 'Sauté',
        skipReasonSuffix:
          'Pour obtenir un résultat pour cette carte santé, vous pouvez mettre à jour vos réponses à tout moment.',
        unanswered: 'Sans réponse',
        unAnsweredDescription:
          'L’évaluation de santé pour cette section n’est pas complète.',
      },
      onboardingStepsHook: {
        personalDetailsLabel: 'Parlez-nous un peu de vous',
        researchAndDataSharingLabel: 'Recherche et partage de données',
        initialQuestionnaireLabel: 'Questionnaire initial',
        viewResultsLabel: 'Voir les résultats',
      },
    },
    insights: {
      baseline_scores: 'Scores de base',
      changes_between_baseline_ratings_and_latest_ratings_by_domain:
        'Changements entre les notes de base et les dernières notes par domaine.',
      clinician_sign_ins: 'Connexions des cliniciens',
      domain_ratings_have_been_normalised_into_a_standard_developed_by_innowell:
        'Les évaluations de domaine ont été normalisées dans une norme développée par Innowell.',
      individual_onboarding: 'Intégration individuelle',
      individual_sign_ins: 'Connexions individuelles',
      initial_domain_ratings_recorded_during_the_selected_time_period:
        'Évaluations initiales du domaine enregistrées pendant la période sélectionnée.',
      insights: 'Connaissances',
      latest_ratings_must_fall_within_selected_time_frame_to_be_displayed_domains_that_have_only_a_baseline_rating_have_been_excluded_from_this_chart:
        "Les dernières notes doivent correspondre à la période sélectionnée pour être affichées. Les domaines qui n'ont qu'une note de base ont été exclus de ce graphique.",
      no_data_available_for_this_period:
        'Aucune donnée disponible pour cette période.',
      no_domains_with_a_baseline_score_can_be_found_within_thin_perion:
        'Aucun domaine avec un score de référence ne peut être trouvé au cours de cette période.',
      note_percentages_have_been_rounded_to_whole_numbers:
        'Remarque : Les pourcentages ont été arrondis aux nombres entiers.',
      number_of_clinician_sign_ins: 'Nombre de connexions de cliniciens',
      number_of_individual_sign_ins: 'Nombre de connexions individuelles',
      recovery_trajectories: 'Recovery Trajectories',
      recovery_trajectory_can_only_be_calculated_with_at_least_two_results_no_domains_with_a_latest_result_found_within_this_period:
        'La trajectoire de récupération ne peut être calculée qu’avec au moins deux résultats. Aucun domaine avec un dernier résultat trouvé au cours de cette période.',
      stb_escalations: 'Escalades STB',
      summary_questionnaire: 'Questionnaire récapitulatif',
      this_graph_visualises_the_number_of_clinician_sign_ins_over_the_specified_period_helping_to_track_employee_engagement_and_activity_on_the_platform_the_graph_will_break_down_the_sign_ins_based_on_the_number_of_times_each_clinician_signed_in:
        "Ce graphique visualise le nombre de connexions de cliniciens sur la période spécifiée, aidant ainsi à suivre l'engagement et l'activité des employés sur la plateforme. Le graphique décomposera les connexions en fonction du nombre de fois où chaque clinicien s'est connecté.",
      this_graph_visualises_the_number_of_individual_sign_ins_over_the_specified_period_helping_to_track_user_engagement_and_activity_on_the_platform_the_graph_will_break_down_the_sign_ins_based_on_the_number_of_times_each_individual_signed_in:
        "Ce graphique visualise le nombre de connexions individuelles sur la période spécifiée, aidant ainsi à suivre l'engagement et l'activité des utilisateurs sur la plateforme. Le graphique décomposera les connexions en fonction du nombre de fois où chaque individu s'est connecté.",
      this_graph_visualises_the_number_of_individuals_who_have_successfully_completed_the_onboarding_process_over_the_specified_period:
        "Ce graphique visualise le nombre de personnes qui ont terminé avec succès le processus d'intégration au cours de la période spécifiée.",
      this_graph_visualises_the_number_of_suicidal_thoughts_and_behaviours_notifications_over_the_specified_period:
        'Ce graphique visualise le nombre de notifications de pensées et de comportements suicidaires sur la période spécifiée.',
      this_graph_visualises_the_number_of_summary_questionnaires_that_have_been_completed_over_the_specified_period_the_top_line_will_show_the_total_number_of_questionnaires_completed_and_the_bottom_line_will_show_the_total_number_of_unique_individuals_that_completed_those_questionnaires:
        'Ce graphique visualise le nombre de questionnaires récapitulatifs qui ont été complétés au cours de la période spécifiée. La ligne du haut indiquera le nombre total de questionnaires remplis et la ligne du bas indiquera le nombre total de personnes uniques ayant rempli ces questionnaires.',
      try_changing_the_date_range_using_the_date_selector_at_the_top_of_the_page:
        "Essayez de modifier la plage de dates à l'aide du sélecteur de date en haut de la page.",
      welcome_to_your_insights_page_we_ll_be_updating_it_regularly_for_you_with_new_features_so_if_there_are_metrics_you_d_like_to_see_or_you_have_questions_let_us_know_at:
        "Bienvenue sur votre page Insights. Nous le mettrons régulièrement à jour pour vous avec de nouvelles fonctionnalités, donc s'il y a des statistiques que vous aimeriez voir ou si vous avez des questions, faites-le nous savoir à",
    },
    onboarding: {
      about_you: 'À propos de vous',
      all_answers_are_secure_and_strictly_confidential:
        'Toutes les réponses sont sécurisées et strictement confidentielles.',
      and: 'et',
      back: 'Retour',
      benefits: 'Avantages',
      by_answering_these_questions_it_helps_you_and_your_provider_better_understand_you:
        'En répondant à ces questions, cela aide vous et votre prestataire à mieux vous comprendre.',
      completed: 'Terminé',
      confirm_password: 'Confirmez le mot de passe',
      continue: 'Continuer',
      create_password: 'Créer un mot de passe',
      enter_a_password: 'Entrez un mot de passe',
      enter_a_username: "Entrez un nom d'utilisateur",
      enter_your_first_name_and_surname: 'Entrez votre prénom et nom',
      enter_your_name: 'Entrez votre nom',
      first_name_and_surname: 'Prénom et nom',
      great_job: 'Bon travail',
      i_understand_and_agree_with_the: "Je comprends et j'accepte les",
      if_you_skip_some_questions_you_can_update_them_later_in_your_profile_settings:
        'Si vous sautez certaines questions, vous pouvez les mettre à jour plus tard dans les paramètres de votre profil.',
      is_not_unique: "n'est pas unique",
      legal_name_is_required: 'Le nom légal est requis',
      loading: 'Chargement',
      name_must_contain_at_least_a_first_name_and_a_surname:
        'Le nom doit contenir au moins un prénom et un nom.',
      of: 'de',
      password_must_be_at_least_8_characters:
        'Le mot de passe doit comporter au moins 8 caractères',
      password_must_be_at_most_20_characters:
        'Le mot de passe doit comporter au maximum 20 caractères',
      password_must_contain_a_mix_of_numbers_and_letters:
        'Le mot de passe doit contenir un mélange de chiffres et de lettres',
      password_must_contain_a_mix_of_upper_and_lower_case_letters:
        'Le mot de passe doit contenir un mélange de majuscules et de minuscules',
      password_must_match: 'Le mot de passe doit correspondre',
      password: 'Mot de passe',
      preferred_name_is_required: 'Le nom préféré est requis',
      preferred_name: 'Nom préféré',
      privacy_policy: 'politique de confidentialité',
      question: 'Question',
      remember_you_can_edit_your_information_at_any_time_from_the_profile_settings_in_your_main_menu:
        "N'oubliez pas, vous pouvez modifier vos informations à tout moment dans les paramètres de profil de votre menu principal",
      repeat_password: 'Répétez le mot de passe',
      save: 'Enregistrer',
      setup_your_innowell_profile_as_everyone_comes_with_a_unique_background:
        'Créez votre profil Innowell, car chacun a un parcours unique.',
      skip: 'Passer',
      sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again:
        "Désolé, une erreur s'est produite sur notre serveur. Nous avons informé notre équipe afin qu'elle puisse y remédier. Veuillez réessayer.",
      start: 'Commencer',
      tell_us_a_bit_about_yourself: 'Parlez-nous un peu de vous',
      terms_and_conditions: 'conditions générales',
      this_is_a_short_set_of_questions_before_the_full_questionnaire_that_should_only_take_you_a_few_minutes_to_complete:
        "Il s'agit d'un court ensemble de questions avant le questionnaire complet, qui devrait prendre quelques minutes pour être complété.",
      unable_to_render_form_without_questionnaire:
        "Impossible d'afficher le formulaire sans le questionnaire",
      unknown_page: 'Page inconnue',
      username: "Nom d'utilisateur",
      what_is_your_preferred_name: 'Quel est votre nom préféré ?',
      you_can_skip_some_of_these_questions_if_you_dont_feel_comfortable_answering_them:
        "Vous pouvez sauter certaines de ces questions si vous ne vous sentez pas à l'aise d'y répondre.",
      you_must_agree_with_the_privacy_policy_and_terms_and_conditions:
        'Vous devez accepter la politique de confidentialité et les conditions générales',
      your_profile: 'Votre profil',
      your_session_has_now_timed_out_click_here_to_sign_in_again:
        'Votre session a expiré. Cliquez ici pour vous reconnecter.',
      your_session_will_timeout_soon_click_here_to_reload_and_avoid_having_to_sign_in_again:
        'Votre session expirera bientôt. Cliquez ici pour recharger et éviter de devoir vous reconnecter.',
    },
    rootPage: {
      userNotificationsComponent: {
        dismiss: 'Rejeter',
      },
    },
    scoreCard: {
      activity_result: 'Résultat de activité',
      are: 'sont',
      clinician: 'clinicien',
      get_started: 'Commencer',
      have: 'avoir',
      has: 'a',
      in_bed: 'Au lit',
      in_progress: 'En cours',
      is: 'est',
      link: 'Lien',
      my_result: 'Mon résultat',
      no_results_for_this_time_period: 'Aucun résultat pour cette période',
      requested: 'Demandé',
      requested_support: "demandé de l'aide",
      request_this: 'Demander ceci',
      sleep_result: 'Résultat du sommeil',
      started: 'commencé',
      steps_over_goal: 'Pas au dessus de lobjectif',
      support: 'Soutien',
      supporting: 'Justificatif',
      their: 'leur',
      this_care_option_requires_your_clinicians_support:
        'Cette option de soins nécessite le soutien de votre clinicien',
      total_steps: 'Nombre total détapes',
      unnamed_user: 'Utilisateur anonyme',
      visit: 'Visite',
      waiting_for: 'En attendant',
      you: 'toi',
      your: 'ton',

      currentResultCardForumulaPage: {
        ago: 'il y a',
        currentResultHeading: 'Résultat actuel:',
        defaultDescription:
          'Pour connaître votre score, remplissez simplement le questionnaire.',
        lastUpdated: 'Dernière mise à jour:',
        unanswered: 'Sans réponse',
      },
      currentResultCardPage: {
        moreInformation: "Plus d'informations sur",
        retakeQuestionnaire: 'Questionnaire de rattrapage',
      },
      scoreCardsCareOptionsPage: {
        all: 'Tout',
        careOptionsDescription:
          'Choisissez parmi les options d’autosoins ou demandez une option auprès d’un clinicien.',
        careOptionsHeading: 'Options de soins',
        selfCare: 'Soins auto-administrés',
        workWithClinician: 'Travailler avec le clinicien',
      },
      scoreCardsChangeLogPage: {
        asOf: 'Dès',
        wasUpdated: 'a été mis à jour.',
        learnMore: 'Apprendre encore plus.',
      },
      scoreCardsNotificationsPage: {
        flashMessage: 'Merci, votre contribution a été enregistrée.',
        linkText: 'notification',
        registeredIndividual: 'Cette personne a enregistré un',
        selectCareOption:
          'Veuillez sélectionner une option de soins, puis soumettre',
        submitButton: 'Soumettre',
        takeAction: "S'il vous plaît, agissez.",
      },
      scoreCardsPreviousAnswersPage: {
        answeredBy: 'Répondu par',
        answeredOn: 'Répondu le',
        completed: 'Complété',
        notSubmitted: 'Non soumis',
        previousAnswersHeading: 'Réponses précédentes',
        questionSet: 'Ensemble de questions',
        rating: 'Notation',
        results: 'Résultats',
        seeAll: 'Voir tout',
        skipped: 'Sauté',
        status: 'Statut',
      },
    },
    shared: {
      no_results_found: 'Aucun résultat trouvé',
      activityLogComponent: {
        activityLogChangeSet: {
          added: 'Ajouté',
          removed: 'Supprimé',
        },
        activityLogCardContainer: {
          noRecentActivity: 'Aucune activité récente',
        },
        recentActivityText: 'Activité récente',
      },
      activityPanelComponent: {
        notesText: 'Remarques',
        recentActivityText: 'Activité récente',
      },
      answerSetComponent: {
        notSubmitted: 'Non soumis',
        submittedAt: 'Soumis à:',
      },
      answerTableComponent: {
        answerColumn: 'Répondre',
        questionColumn: 'Question',
      },
      chartHeaderComponent: {
        backToDashboard: 'Retour au tableau de bord',
        dashboard: 'Tableau de bord',
        healthHistory: 'Antécédents de santé',
        profile: 'Profil',
      },
      clinicianDropdownComponent: {
        addNote: 'Ajouter une note',
        addNoteHeading: 'Ajouter une remarque',
        giveInputText: 'Donner votre avis',
        toolTipText: 'Actes',
        viewHealthHistory: "Afficher l'historique de santé",
        viewProfile: 'Voir le profil',
      },
      clinicianNoteComponent: {
        modalHeading: 'Créer une note',
        noClinicianNote: 'Aucune note du clinicien',
        saveButton: 'Sauvegarder',
        textFieldPlaceholder: 'Saisir une note',
      },
      connectedTenantsPage: {
        cliniciansHeading: 'Cliniciens',
        connectedOrganizationHeading: 'Organisations connectées et accès',
        manageAccessDescription:
          "Pour gérer l'accès à vos données veuillez contacter le service concerné",
        organizationsHeading: 'Organisations',
        workingWithOrganizations:
          'Sur cette page, vous pouvez voir les organisations et les cliniciens avec lesquels vous travaillez',
      },
      dataGridRowFooterContentComponent: {
        copyMessageFailed: 'La copie a échoué.',
        copyMessageSuccess: 'Copié dans le presse-papiers.',
        helpRequested: 'Aide demandée',
        lastSeenFormat: 'il y a',
        group: {
          one: {
            dob: 'Date de naissance',
            email: 'Adresse email',
            preferredName: 'Nom préféré',
          },
          two: {
            initialQuestionnaire: 'Questionnaire initial',
            lastActive: 'Dernière activité',
            medicalDirectorID: 'ID du directeur médical',
          },
        },
      },
      enterPasswordPage: {
        confirmNewPassword: 'Confirmer le nouveau mot de passe',
        confirmNewPasswordPlaceholder: 'Répéter le mot de passe',
        newPassword: 'Nouveau mot de passe',
        newPasswordPlaceholder: 'Entrez le nouveau mot de passe',
      },
      healthHistoryPage: {
        notificationHistory: 'Historique des notifications',
        downloadReportTaskButton: {
          reportLabel: 'Rapport',
        },
        myEscalationsTable: {
          dateTime: 'Date / Heure',
          healthCard: 'Carte Santé',
        },
        healthHistory: {
          unanswered: 'Sans réponse',
        },
      },
      errorPage: {
        backButton: "Retour à l'accueil",
        pageDescription: "Code d'erreur : {{errorCode}}",
        '404PageHeading': 'Nous ne trouvons pas cette page',
        genericPageHeading: 'Oups !',
        '500ErrorDescrption':
          "Un problème est survenu. Veuillez réessayer plus tard. Si vous avez besoin d'aide immédiate, veuillez contacter votre service ou nous envoyer un e-mail de support.",
        '502ErrorDescrption':
          "Un problème est survenu, mais cela devrait être temporaire. Veuillez attendre quelques instants et essayer de rafraîchir la page ou revenir plus tard. Si vous avez besoin d'aide immédiate, veuillez contacter votre service ou nous envoyer un e-mail de support.",
        '504ErrorDescrption':
          "Désolé, cette page met trop de temps à se charger. Veuillez attendre quelques instants et essayer de rafraîchir la page ou revenir plus tard. Si vous avez besoin d'aide immédiate, veuillez contacter votre service ou nous envoyer un e-mail de support.",
        pageHeading: 'Erreur',
      },
      helpNowButtonComponent: {
        call: 'Appel',
        chat: 'Chat',
        modalHeading: "Besoin d'aide maintenant?",
        needHelpLong: "J'ai besoin d'aide maintenant",
        needHelpShort: "Besoin d'aide",
        website: 'Site web',
      },
      fitbitPages: {
        fitbitConnectActivity: {
          and: 'et',
          bulletPoints: {
            one: 'Suivez votre activité et la progression de votre sommeil.',
            two:
              'Consultez vos données Fitbit dans le contexte de votre santé et de votre bien-être',
            three:
              'Recevez des mises à jour en temps réel via votre tableau de bord',
          },
          description:
            "Vous pouvez toujours vous déconnecter/reconnecter à tout moment. Voici plus d'informations sur Innowell",
          interestedButton: 'Oui, je veux me connecter',
          modalHeading:
            'Avantages de la connexion de Fitbit à la plateforme Innowell',
          notInterestedButton: 'Non, pas intéressé',
          privacyPolicy: 'politique de confidentialité',
          termsAndConditions: 'termes et conditions',
        },
        fitbitConnectionCard: {
          connectFitBitText: 'Connectez votre Fitbit',
          description:
            'Nous savons qu’il existe un lien important entre la forme physique, l’exercice, le sommeil et la santé mentale. C’est la raison pour laquelle Innowell s’est associé à Fitbit.',
          disconnectAriaLabel: 'Déconnectez votre Fitbit',
          disconnectButtonText: 'Déconnecter',
        },
        fitbitDisconnectActivity: {
          cancelButton: 'Annuler',
          description:
            'La synchronisation de votre Fitbit avec la Plateforme vous permet de visualiser vos informations de santé et de bien-être en un seul endroit.',
          disconnectButtonText: 'Déconnecter',
          modalHeading: 'Etes-vous sûr de vouloir vous déconnecter ?',
        },
      },
      loadMoreComponent: {
        loadMore: 'Charger plus',
      },
      releaseWarningBannerComponent: {
        clickHere: 'Cliquez ici',
        newVersionTextOne:
          "Une nouvelle version de l'application a été publiée.",
        please: "S'il te plaît",
        reloadMessage: 'pour recharger et éviter les erreurs.',
      },
      requestAssessmentButtonComponent: {
        assessments: 'Évaluations',
        requestAssessmentButtonAria: 'bouton demander une évaluation',
        requestedAssessments: 'Évaluations demandées',
      },
      researchDataSharingPage: {
        contributingMessage:
          "La contribution de vos données dépersonnalisées est totalement volontaire et vous pouvez changer d'avis à tout moment. De plus, votre décision de permettre ou non l'utilisation de vos données dépersonnalisées à des fins de recherche n'affectera pas vos soins cliniques habituels, ni votre relation actuelle ou future avec votre prestataire de services, ou professionnel de la santé, ou Innowell.",
        forMoreInformation:
          "Pour plus d'informations sur la façon dont Innowell utilise les données, veuillez consulter le",
        lastModified: 'Dernière modification : juin 2022',
        personalInformationDescription:
          "Vos informations personnelles seront toujours stockées dans la plateforme Innowell et seules vos données dépersonnalisées seront utilisées à des fins de recherche. Les données dépersonnalisées seront extraites, utilisées pour une analyse statistique et pour améliorer l'efficacité globale de la plateforme Innowell.",
        personalInformationMessage:
          'Vos informations personnelles sont dépersonnalisées et personne ne peut lier votre nom, adresse e-mail ou date de naissance à vos informations de santé.',
        researchDataSharingHeading: 'Partage de données de recherche',
        thankYouMessage:
          'Merci de partager vos données dépersonnalisées avec Innowell.',
      },
      readOnlyModePage: {
        contactService: 'contactez votre service pour modifier ces paramètres.',
        readOnlyMode: 'Vous êtes en mode lecture seule.',
      },
      rolePage: {
        dataPolicy: 'politique de données',
        description:
          'Votre service participe au(x) programme(s) de recherche suivant(s) pour développer une compréhension plus approfondie des besoins des gens. Ces informations sont utilisées pour améliorer la prestation de soins centrés sur la personne. La contribution de vos données anonymisées est entièrement volontaire et n’affectera pas vos soins cliniques. Lisez notre',
        loadingSpinnerMessage: 'Changer les rôles',
        moreInformation:
          'pour plus d’informations sur la manière dont vos données seront utilisées.',
        researchPageHeading: 'Recherche et partage de données',
        suspenseLoadingMessage: 'Page de partage de données de recherche',
        flashMessage: 'Merci, votre sélection a été enregistrée.',
      },
      staffDownloadReportButtonComponent: {
        continueButton: 'Continuer',
        modalDescription:
          'Veuillez vous assurer de prendre les mesures appropriées pour protéger la confidentialité de ce document conformément aux exigences de gouvernance clinique de votre service.',
        modalHeading:
          'Téléchargement des résultats - avertissement de sécurité',
        reportButton: 'Rapport',
      },
      staffNavigationTabs: {
        clinicians: 'Les cliniciens',
        employees: 'Employées',
        individuals: 'Individus',
        managers: 'Gestionnaires',
        notifications: 'Notifications',
      },
      summaryGraphComponent: {
        healthSnapshot: 'Aperçu de la santé',
        healtyLabel: 'En bonne santé',
        moveRangeBack: 'Reculer la plage horaire',
        moveRangeForward: 'Avancer la plage horaire',
        seeMoreDetails: 'Voir plus de détails',
      },
      sessionTimeoutWarningComponent: {
        sessionTimeoutMessage:
          'Votre session a expiré. Veuillez vous reconnecter pour continuer.',
      },
      supportPersonModalComponent: {
        addAnotherPerson: 'Ajouter une autre personne',
        anotherInvite: 'Un autre',
        description:
          'Différents points de vue peuvent vous aider à enrichir votre profil de santé. Ils recevront une invitation par courriel pour répondre à un questionnaire sur votre santé et votre bien-être.',
        inviteText: 'Inviter',
        oneInvite: 'un',
        paragraphOne:
          'Ils ne pourront pas consulter vos informations de santé.',
        paragraphTwo:
          'Si vous supprimez une personne de soutien, elle ne sera pas avertie.',
        supportPersonText: 'Personne de soutien',
        yourSupportPersonHeading: 'Votre personne de soutien',
      },
      supportTaskButtonComponent: {
        ariaLabelText: 'ajouter une personne de soutien',
        headingText: 'Ajouter une personne de soutien',
        paragraphOne:
          'Différentes perspectives peuvent vous aider à ajouter plus d’informations à votre profil de santé. Ils recevront une invitation par e-mail pour répondre à un questionnaire sur votre santé et votre bien-être.',
        paragraphTwo:
          'Ils ne pourront consulter aucune de vos informations de santé. Si vous supprimez une personne d’assistance, elle ne sera pas informée.',
        supportForm: {
          emailLabel: 'E-mail',
          emailPlaceholder: "Ajoutez l'e-mail de votre personne d'assistance",
          inviteButtonText: 'Inviter une personne de soutien',
          nameLabel: 'Nom',
          namePlaceholder: 'Ajoutez le nom de votre personne de soutien',
          relationship: 'Relation',
          validations: {
            nicknameValidation: 'Le nom de la personne de soutien est requis',
            relationshipValidation: 'Type de relation invalide',
            supportEmailRequired:
              'L’e-mail de la personne d’assistance est requis',
            supportEmailValidation:
              'L’e-mail de la personne d’assistance doit être un e-mail valide',
          },
        },
        supportInfo: {
          ago: 'il y a',
          email: 'E-mail',
          emailText: 'E-MAIL',
          getSupportInput: 'Obtenir de l’aide',
          invitationSentText: 'INVITATION ENVOYÉE À:',
          lastCompleted: 'Dernière réalisation:',
          lastRequested: 'Dernière demande:',
          nameLabel: 'Nom',
          reinviteSupport: "Réinviter l'assistance",
          relationship: 'Relation',
          removeButton: 'Retirer',
          removedMessageDescription:
            'Ils ne seront pas informés de leur suppression.',
          removedMessageText: 'A ÉTÉ SUPPRIMÉ',
          requestHistory: 'Historique des demandes',
          sentTo: 'ENVOYÉ À',
          supportRequestText: "DEMANDE D'ASSISTANCE",
        },
      },
      trackableChartToggleComponent: {
        checkoutMessage: 'Découvrez le nouveau',
        new: 'NOUVEAU!',
        oldDashboard: "à l'ancien tableau de bord récapitulatif.",
        switchBack: 'Revenir en arrière',
        trendGraphs: 'graphiques de tendance',
      },
      userQuestionnaireComponent: {
        benefitsOfInnowellFour:
          'Remplissez le questionnaire en une seule fois, de préférence, ou revenez au fil du temps si nécessaire.',
        benefitsOfInnowellHeading: "Avantages d'Innowell",
        benefitsOfInnowellOne:
          'Les résultats du questionnaire sont partagés avec votre clinicien pour aider à soutenir vos soins de santé mentale.',
        benefitsOfInnowellThree:
          'À chaque domaine de santé sont associées des activités, appelées options de soins, qui fournissent des outils d’amélioration.',
        benefitsOfInnowellTwo:
          'Accès immédiat à vos résultats et à un aperçu holistique de votre santé et de votre bien-être.',
        importantNoteOne:
          "La plateforme Innowell n'est pas un outil de diagnostic.",
        importantNoteTwo:
          'Vos informations sont sécurisées et strictement confidentielles.',
        importantNotesHeading: 'Notes importantes',
        startButton: 'Commencer',
      },
      unauthorisedPage: {
        backButton: "Retour à l'accueil",
        pageDescription:
          "Vous n'êtes pas autorisé à accéder à cette page. Pour y accéder, veuillez contacter votre superviseur ou l'administrateur système.",
        pageHeading: 'Accès non autorisé',
      },
      staff: {
        staffDashboardSelectionComponent: {
          ageAndGender: 'ÂGE / SEXE',
          backToListAriaLabel: 'Retour à la liste individuelle',
          email: 'E-MAIL',
          filterAriaLabel: 'filtre',
          individualInfo: 'Informations Individuelles',
          initialScore: 'NOTE INITIALE',
          lastActive: 'DERNIER ACTIF',
          moreInfo: "Plus d'informations",
          switchIndividual: 'Changer de personne',
        },
      },
      hooks: {
        usePersonalDetailsHook: {
          copyMessageFailed: 'La copie a échoué.',
          copyMessageSuccess: 'Copié dans le presse-papiers.',
          lastSeenFormat: 'il y a',
        },
      },
    },
    staff: {
      employee: {
        staffEmployeesTableComponent: {
          emailAddress: 'Adresse email',
          employeeName: "Nom de l'employé",
          role: 'Rôle(s)',
        },
        staffEmployeesMultiSelectComponent: {
          emptyStateText:
            'Aucune action commune. Assurez-vous que les lignes sélectionnées ont un rôle commun.',
          remove: 'Supprimer',
          resend: 'Renvoyer',
          removeRole: 'Supprimer le rôle',
          resendInvitation: "Renvoyer l'invitation",
          role: 'Rôle',
          invitation: 'Invitation',
        },
        staffEmployeesRemoveEmployeesModalComponent: {
          removeRole: 'Supprimer le rôle',
          bodyTextOne: 'Vous êtes sur le point de retirer',
          from: 'de',
          role: 'rôle',
          employee_one: 'employé',
          employee_other: 'employés',
          confirmationText: 'Êtes-vous sûr de vouloir continuer ?',
          buttonTextNo: 'Non',
          buttonTextYes: 'Oui, supprimer le rôle',
        },
        staffEmployeesResendEmployeesModalComponent: {
          removeRole: 'Renvoyer les invitations à des rôles',
          bodyTextOne: 'Vous êtes sur le point de retirer',
          for: 'pour',
          invitations: 'invitations',
          employee_one: 'employé',
          employee_other: 'employés',
          confirmationText: 'Êtes-vous sûr de vouloir continuer ?',
          buttonTextNo: 'Non',
          buttonTextYes: 'Oui, renvoyer les invitations',
        },
      },
      individual: {
        assignCliniciansModalPage: {
          assign: 'Attribuer',
          clinicians: 'Cliniciens',
          loading: 'chargement',
        },
        dischargeModalPage: {
          bodyTextOne: 'Vous êtes sur le point de décharger',
          bodyTextThree:
            'y compris ceux dont les notifications ne sont pas résolues.',
          bodyTextTwo:
            'Veuillez noter que toutes les personnes seront libérées',
          buttonTextNo: 'Non',
          buttonTextYes: 'Oui, décharge',
          confirmationText: 'Etes-vous sûr de vouloir continuer ?',
          discharge: 'Décharge',
          individual_one: 'individuel',
          individual_other: 'individus',
        },
        individualsTablePage: {
          assignClinicians: 'Affecter des cliniciens',
          discharge: 'Décharge',
          reinvite: 'Réinviter',
          remove: 'Retirer',
          requestAssessments: "Demande d'évaluation",
          resendInvitations: 'Renvoyer les invitations',
          tinyLoaderMessage: 'Chargement',
        },
        reinviteIndividualsModalPage: {
          bodyTextOne: 'Vous êtes sur le point de réinviter',
          buttonTextNo: 'Non',
          buttonTextYes: 'Oui, réinviter',
          confirmationText: 'Etes-vous sûr de vouloir continuer ?',
          reinviteSent: 'Réinvitations Envoyées',
          reinvite: 'Ré-inviter',
          individual_one: 'individuel',
          individual_other: 'individus',
          invitation_one: 'invitation',
          invitation_other: 'invitations',
          summaryText:
            'Réinvitations envoyées. Veuillez patienter environ 5 minutes pour que les réinvitations parviennent aux personnes concernées.',
          sent: 'envoyé',
          failed: 'échoué',
          done: 'Fait',
          failedSummaryText:
            'Following invitations may have failed because they are currently active in another tenant.',
        },
        resendInvitationsModalPage: {
          bodyTextOne: 'Vous êtes sur le point de renvoyer',
          buttonTextNo: 'Non',
          buttonTextYes: 'Oui, renvoyer',
          confirmationText: 'Êtes-vous sûr de vouloir continuer ?',
          invitationSent: 'Invitations envoyées',
          resend: 'Renvoyer',
          individual_one: 'individuel',
          individual_other: 'individus',
          invitation_one: 'invitation',
          invitation_other: 'invitations',
          invitationTo_one: 'une invitation à',
          invitationTo_other: 'invitations à',
          summaryText:
            'Invitations envoyées. Veuillez prévoir environ 5 minutes pour que les réinvitations parviennent aux individus.',
          sent: 'envoyé',
          failed: 'échoué',
          done: 'Fait',
          failedSummaryText:
            'Les invitations suivantes peuvent avoir échoué car elles sont actuellement actives dans un autre locataire.',
        },
        removeIndividualsModalPage: {
          bodyTextOne: 'Vous êtes sur le point de supprimer',
          buttonTextNo: 'Non',
          buttonTextYes: 'Oui, supprimer',
          confirmationText: 'Êtes-vous sûr de vouloir continuer ?',
          remove: 'Supprimer',
          individual_one: 'individu',
          individual_other: 'individus',
        },
        requestAssessmentModalPage: {
          requestAssessments: "Demandes d'évaluations",
          sendRequest: 'Envoyer la demande',
          placeholder: 'Rechercher un questionnaire...',
          selectQuestionnaire: 'Sélectionner le(s) questionnaire(s) à demander',
          loading: 'Chargement',
        },
        staffIndividualsTableFooterPage: {
          copyMessageFailed: 'La copie a échoué.',
          copyMessageSuccess: 'Copié dans le presse-papiers.',
          helpRequested: 'Aide demandée',
          lastSeenFormat: 'il y a',
          group: {
            one: {
              dob: 'Date de naissance',
              email: 'Adresse email',
              preferredName: 'Nom préféré',
            },
            two: {
              initialQuestionnaire: 'Questionnaire initial',
              lastActive: 'Dernière activité',
            },
          },
        },
        standardIndividualsTableComponentPage: {
          clinicians: 'Les cliniciens',
          patientName: 'Nom du patient',
          status: 'Statut',
        },
      },
      clinician: {
        clinicianTablePage: {
          assignIndividuals: 'Attribuer des personnes',
          tinyLoaderMessage: 'Chargement',
        },
        assignIndividualsModalPage: {
          assign: 'Attribuer',
          bodyTextOne: "Vous êtes sur le point d'attribuer",
          bodyTextTwo: 'aux cliniciens sélectionnés',
          bodyTextThree: "n'a pas pu être attribué",
          assigned: 'Attribué',
          bodyTextFour:
            'Les invitations suivantes peuvent avoir échoué car un ou plusieurs cliniciens ne sont peut-être pas encore actifs',
          clinician_one: 'clinician',
          clinician_other: 'clinicians',
          continue: 'Continuer',
          confirmationText: 'Etes-vous sûr de vouloir continuer ?',
          individuals: 'Individus',
          individual_one: 'individuel',
          individual_other: 'individus',
          loading: 'chargement',
          summaryText:
            'affectées. Veuillez prévoir environ 5 minutes pour que les devoirs soient terminés.',
          done: 'Fait',
          buttonTextNo: 'Non',
          buttonTextYes: 'Oui, attribuer',
        },
      },
      esclation: {
        staffEscalationsTableDataGridPage: {
          actionedBy: 'Agi par',
          and: 'et',
          careOption: 'Option de soins mise en œuvre',
          createdDate: 'Date',
          healthCard: 'Carte de santé',
          more: 'plus...',
          patientName: 'Nom du patient',
          primaryClinician: 'Médecin traitant',
          reason: 'Raison',
          resolvedAt: 'Date',
        },
      },
    },
    supporting: {
      age_range: "Tranche d'âge",
      call: 'Appel',
      chat: 'Chatte',
      description: 'Description',
      if_you_or_someone_you_know_need_immediate_help_call:
        "Si vous ou quelqu'un que vous connaissez avez besoin d'une aide immédiate, appelez",
      need_help_now: "Besoin d'aide maintenant ?",
      now: 'maintenant.',
      phone_hours: 'Horaires de téléphone',
      talk_to_someone: "Parler à quelqu'un",
      text: 'Texte',
      twenty_four_hours_a_day_seven_days_a_week:
        '24 heures sur 24, 7 jours sur 7',
      website: 'Site web',

      supportingPage: {
        supportingSupporteeInfoWrapper: {
          contactOptionOne:
            "Il n'y a pas de bonne ou de mauvaise façon de soutenir quelqu'un, souvent la première étape consiste à avoir une conversation ouverte. Bien que cela puisse être difficile, consultez le",
          contactOptionTwo:
            'site web pour vous aider à mieux planifier ces conversations.',
          firstContentOne:
            'Ce questionnaire aidera à fournir une perspective supplémentaire sur la santé et le bien-être de la personne que vous soutenez.',
          firstContentTwo:
            'Vos réponses ne seront vues que par la personne que vous soutenez et leurs cliniciens associés.',
          for: 'pour',
          infoCardTitleOne: 'Informations importantes',
          infoCardTitleTwo: "Que puis-je faire d'autre maintenant ?",
          or: 'ou',
        },
        supportingSupporteeQuestionnaire: {
          readyToSubmitButtonLabel: 'Prêt à soumettre',
          startButtonLabel: 'Commencer le questionnaire',
          submitteduttonLabel: 'Soumis',
        },
        supportingSupporteeQuestionnairesList: {
          heading:
            'Vous avez été invité à répondre aux questionnaire(s) suivant(s) pour...',
          summaryQuestionnaire: 'Questionnaire de résumé',
        },
        supportingSupporteeQuestionnaireSubmissionComponent: {
          submitButton: 'Soumettre',
          submittingButton: 'Soumission...',
          summaryQuestionnaire: 'Questionnaire de résumé',
        },
        supportingSupporteeQuestionnaireSubmissionPage: {
          albertaHealthServices: "Services de santé de l'Alberta",
          callNumber: ', ou appelez le 519-646-6000, poste 65196',
          chatForLife: 'Chats for Life',
          checkIn: 'Enregistrement',
          description:
            "Il n'y a pas de bonne ou de mauvaise façon de soutenir quelqu'un, souvent la première étape consiste à avoir une conversation ouverte. Bien que cela puisse être difficile, consultez le",
          kidsHelpPhone: 'Kids Help Phone',
          oftenAnswerQuestionnaire:
            'À quelle fréquence devez-vous répondre à ce questionnaire ?',
          oftenAnswerQuestionnaireDescription:
            'Vous pouvez répondre à nouveau au questionnaire à tout moment en vous connectant à la plateforme Innowell. La personne dont vous vous occupez peut également envoyer une demande par e-mail pour que vous mettiez à jour le questionnaire.',
          or: 'ou',
          pageHeading: "Merci d'avoir complété ce questionnaire avec succès.",
          questionnaireDescription:
            'Ce questionnaire aidera à fournir différentes perspectives sur la santé et le bien-être de la personne dont vous vous occupez.',
          seeYourAnswerDescription:
            'Vos réponses seront vues par la personne dont vous vous occupez et leurs cliniciens associés.',
          toHelpWithConvo:
            'pour vous aider à mieux planifier ces conversations.',
          website: 'site web',
          websiteOrContact: 'site web, ou contactez',
          whatElseHeading: "Que puis-je faire d'autre maintenant ?",
          whatIsQuestionnaireHeading: 'À quoi sert ce questionnaire ?',
          WhoSeeYourAnswers: 'Qui verra vos réponses ?',
        },
        supportingSupporteeRemoveModal: {
          removeYourSelf: 'Retirez-vous en tant que personne de soutien',
          closeButton: 'Fermer',
          removeButton: 'Retirer',
        },
        supportingSupporteeSelectionPage: {
          notSupportingHeading: 'Vous ne soutenez personne',
          welcomeMessage:
            "Bienvenue {{name}} et merci pour votre temps aujourd'hui !",
        },
      },
    },
    settings: {
      userSettingsPage: {
        manageSettingsHeading: 'Gérez vos paramètres',
        profile: 'Profil',
        password: 'Mot de passe',
        healthHistory: 'Historique de santé',
        cliniciansAndHealthcareProviders:
          'Cliniciens et prestataires de soins de santé',
        researchAndDataSharing: 'Recherche et partage de données',
        smsNotifications: 'Notifications par SMS',
        contactUs: 'Contactez-nous',
      },
      userNotificationPage: {
        descriptionOne:
          'Votre numéro de mobile sera utilisé pour vous envoyer des rappels et des notifications. Vous pouvez activer ou désactiver les notifications par SMS à tout moment.',
        descriptionTwo:
          "Innowell ne partagera jamais votre numéro de mobile. Si vous souhaitez plus d'informations sur vos données, veuillez consulter notre",
        notificationsSettingsLoadingMessage: 'Paramètres des notifications',
        privacyPolicy: 'politique de confidentialité.',
        smsNotificationsHeading: 'Notifications par SMS',
      },
      userSettingsUserFieldPage: {
        emailAddressTitle: 'Adresse email',
        emailTitle: 'Email',
        legalNameTitle: 'Nom légal',
        legalNameValidation:
          'Le nom légal doit contenir au moins un prénom et un nom de famille.',
        mobileNumber: 'Numéro de mobile',
        mobileNumberDescriptionOne:
          'Votre numéro de mobile sera utilisé pour vous envoyer des rappels et des notifications. Vous pouvez activer ou désactiver les notifications à tout moment sur la page `vos notifications`.',
        mobileNumberDescriptionTwo:
          "Innowell ne partagera jamais votre numéro de mobile. Si vous souhaitez plus d'informations sur vos données, veuillez consulter notre",
        mobileNumberValidation:
          'Le numéro de mobile doit comporter 10 chiffres et commencer par 04, par exemple 0426664444',
        phoneNumberPlaceholder: 'par ex. 0426664444',
        phoneNumberTitle: 'Ajoutez votre numéro de mobile',
        preferredLanguageTitle: 'Langue préférée',
        preferredNameTitle: 'Nom préféré',
        privacyPolicy: 'Politique de confidentialité',
        submitEmail: "Mettre à jour l'email",
        submitMobileNumber: 'Mettre à jour le numéro de téléphone',
        submitPreferredLanguage: 'Mettre à jour la langue préférée',
        submitUpdateName: 'Mettre à jour le nom',
        submitUpdatePreferredName: 'Mettre à jour le nom',
        submitUsername: "Mettre à jour le nom d'utilisateur",
        usernameTitle: "Nom d'utilisateur",
        usernameUniquenessError: "Désolé, ce nom d'utilisateur est déjà pris",
        whatsYourEmail: 'Quelle est votre adresse email ?',
        whatsYourLegalName: 'Quel est votre nom légal ?',
        whatsYourPreferredLanguage: 'Quelle est votre langue préférée ?',
        whatsYourPreferredName: 'Quel est votre nom préféré ?',
        whatsYourUsername: "Quel est votre nom d'utilisateur ?",
      },
      userSettingsPersonalDetailsEditPage: {
        backButton: 'Retour',
        updateButton: 'Mettre à jour',
      },
      userSettingsPersonalDetailsPage: {
        emailWarning: "Changement d'email demandé, en attente de confirmation.",
        manageInformation:
          'Sur cette page, vous pouvez gérer vos informations, y compris vos coordonnées.',
        profileSettingHeading: 'Paramètres du profil',
      },
    },
  },
}

export default resources
