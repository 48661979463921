// @flow

import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { type PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { useQueryLoader } from 'react-relay'
import { get } from 'lodash'

import { recoveryTrajectoriesQuery } from 'platform_web/pages/Insights/tabs/Outcomes/queries/RecoveryTrajectoriesQuery'
import {
  Box,
  FlexContainer,
  HorizontalStackBar,
  IconNew,
  ScrollableBox,
  Tooltip,
} from 'care-ui'
import { InfoIcon } from 'care-ui/atoms/icons-new'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import type { OutcomeReportingQueryVariableType } from '../../hooks/useOutcomeReporting'
import { RECOVERY_TRAJECTORIES_LEGENDS } from '../../hooks/useOutcomeReporting'
import RecoveryTrajectoriesEmptyState from '../RecoveryTrajectoriesEmptyState'

import { type RecoveryTrajectoriesQuery } from '../../queries/__generated__/RecoveryTrajectoriesQuery.graphql'

type ComponentProps = {
  queryReference: PreloadedQuery<RecoveryTrajectoriesQuery>,
}

const Component = (props: ComponentProps) => {
  const { queryReference } = props

  const queryResult = usePreloadedQuery(
    recoveryTrajectoriesQuery,
    queryReference,
  )
  const recoveryTrajectories = get(
    queryResult,
    'viewer.outcome_report.recovery_trajectories',
  )

  const hasRecoveryTrajectories = !!recoveryTrajectories.length

  const { t: translation } = useTranslation('insights')

  return (
    <Box marginY="xl">
      <TextNew as="div" typography="h2">
        {translation('recovery_trajectories')}
      </TextNew>

      <FlexContainer alignItems="center" justifyContent="space-between">
        <FlexContainer alignItems="center">
          <TextNew as="span" typography="bodyMd">
            {translation(
              'changes_between_baseline_ratings_and_latest_ratings_by_domain',
            )}
          </TextNew>

          <Tooltip
            text={translation(
              'latest_ratings_must_fall_within_selected_time_frame_to_be_displayed_domains_that_have_only_a_baseline_rating_have_been_excluded_from_this_chart',
            )}
            note={translation(
              'note_percentages_have_been_rounded_to_whole_numbers',
            )}
            variant="info"
            width="480px"
          >
            <IconNew as={InfoIcon} />
          </Tooltip>
        </FlexContainer>

        <FlexContainer gap="xxs" alignItems="center">
          {hasRecoveryTrajectories &&
            RECOVERY_TRAJECTORIES_LEGENDS.map(legend => (
              <FlexContainer
                alignItems="center"
                justifyContent="center"
                key={legend.name}
              >
                <Box
                  width="16px"
                  height="16px"
                  marginX="4px"
                  backgroundColor={legend.color}
                />
                <TextNew as="span" typography="bodyMd">
                  {legend.name}
                </TextNew>
              </FlexContainer>
            ))}
        </FlexContainer>
      </FlexContainer>

      {hasRecoveryTrajectories && (
        <ScrollableBox width="1156px" direction="horizontal">
          <FlexContainer direction="column" gap="sm" marginTop="sm">
            {recoveryTrajectories.map(item => (
              <HorizontalStackBar
                key={item.health_domain_label}
                title={item.health_domain_label}
                total={item.total}
                series={item.series}
              />
            ))}
          </FlexContainer>
        </ScrollableBox>
      )}

      {!hasRecoveryTrajectories && <RecoveryTrajectoriesEmptyState />}
    </Box>
  )
}

type RecoveryTrajectoriesProps = {
  outcomeReportQueryVariables: OutcomeReportingQueryVariableType,
}

// Query Loader
const RecoveryTrajectories = (props: RecoveryTrajectoriesProps) => {
  const { outcomeReportQueryVariables } = props
  const { quarter_start_date } = outcomeReportQueryVariables

  const [
    recoveryTrajectoriesQueryReference,
    loadRecoveryTrajectoriesQuery,
  ] = useQueryLoader(recoveryTrajectoriesQuery)

  // Load query when query variables change
  useEffect(
    () => {
      if (!quarter_start_date) return
      loadRecoveryTrajectoriesQuery(outcomeReportQueryVariables)
    },
    [outcomeReportQueryVariables],
  )

  return (
    <Box marginY="xl">
      <Suspense>
        {recoveryTrajectoriesQueryReference != null && (
          <Component queryReference={recoveryTrajectoriesQueryReference} />
        )}
      </Suspense>
    </Box>
  )
}

export default RecoveryTrajectories
